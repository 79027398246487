import React, { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import parseJSON from 'date-fns/parseJSON'

import { programs as programsResource } from '../../redux/actions/resource'

import { getAllPrograms, getCurrentProject } from '../../redux/reducers'

import Action from '../../components/Action'
import { confirm } from '../../components/Alert'
import Icon from '../../components/Icon'
import ListToolbar from '../../components/ListToolbar'
import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import Tile, { Grid, GridItem, LabelledValue } from '../../components/Tile'
import { TextLink } from '../../components/type'
import Toolbar from '../../components/Toolbar'

const program = Symbol('program')

const ProgramListItem = withRouter(
  ({
    id,
    name,
    publishAttempt,
    onDeleteProgram,
    t,
    sortOrder,
    swapSortOrder,
    currentProjectId,
    state,
    summary,
    startsAt,
    endsAt,
    history,
  }) => {
    const ref = useRef()

    const [, drag] = useDrag({
      item: { id, sortOrder, type: program },
    })
    const [, drop] = useDrop({
      accept: program,
      drop: (item, monitor) => {
        if (!ref.current) {
          return
        }

        const dragIndex = item.sortOrder
        const hoverIndex = sortOrder

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        swapSortOrder({
          projectId: currentProjectId,
          a: item.id,
          b: id,
        })
      },
    })
    drag(drop(ref))

    const failed = (publishAttempt || {}).succeeded === false
    const title = `${name}${failed ? ' ⚠️' : ''}`

    const active = ['running', 'expiring'].includes(state) ? 1 : 0

    return (
      <GridItem ref={ref} order={sortOrder}>
        <Tile
          title={title}
          onClick={() => history.push(`/programs/${id}`)}
          footer={`${t('format:dateTimeShort', {
            date: parseJSON(startsAt),
          })} - ${t('format:dateTimeShort', { date: parseJSON(endsAt) })}`}
          centered
          actions={
            <Action
              onClick={(e) => {
                onDeleteProgram(id)
                e.stopPropagation()
              }}
            >
              <Icon name="delete" color="#fff" border="#fff" />
            </Action>
          }
        >
          <LabelledValue
            value={active}
            label={t('attributes:program:active')}
          />
          {(summary || []).map(({ entity_type, scope, count }, index) => {
            const txt = `${
              scope == null ? 'models:' : 'attributes:'
            }${entity_type}${
              scope == null
                ? ''
                : scope == 'suspended'
                ? ':modes:1'
                : ':modes:0'
            }`
            return (
              <LabelledValue
                key={index}
                value={count}
                label={t(txt, { count: count })}
              />
            )
          })}
        </Tile>
      </GridItem>
    )
  }
)

const RunningIndicator = styled.span`
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.brandSecondary};
`

const ProgramList = ({ t, history }) => {
  const programs = useSelector(getAllPrograms)
  const currentProject = useSelector(getCurrentProject)
  const projectId = currentProject?.id
  const dispatch = useDispatch()

  const swapSortOrder = (a, b) => dispatch(programsResource.swapSortOrder(a, b))

  useEffect(() => {
    dispatch(programsResource.list({ projectId }))
  }, [dispatch])

  const onSearch = (search) => {
    if (currentProject) {
      dispatch(programsResource.list({ projectId, search }))
    }
  }

  const onDeleteProgram = async (id) => {
    if (await confirm('Möchtest du dieses Programm wirklich löschen?')) {
      dispatch(programsResource.destroy(id))
    }
  }

  return (
    <>
      <Toolbar
        title={t('models:program', { count: 0 })}
        onBack={() => history.push('/dashboard')}
      >
        <Action to="/programs/new">
          <Icon name="add" />
        </Action>
      </Toolbar>
      <ListToolbar onSearch={onSearch}>
        {({ showTiles }) => {
          return showTiles ? (
            <Grid>
              {(programs || []).map((program, index) => {
                return (
                  <ProgramListItem
                    key={index}
                    {...program}
                    onDeleteProgram={onDeleteProgram}
                    swapSortOrder={swapSortOrder}
                    currentProjectId={currentProject?.id}
                    t={t}
                  />
                )
              })}
            </Grid>
          ) : (
            <Table spacious divided>
              <Head>
                <Row>
                  <Header colSpan={2}>
                    {t('models:program', { count: 0 })}
                  </Header>
                </Row>
              </Head>
              <Body>
                {(programs || []).map(({ id, name, state }, index) => {
                  return (
                    <Row key={index}>
                      <Column>
                        <TextLink to={`/programs/${id}`}>
                          {name}{' '}
                          {['running', 'expiring'].includes(state) && (
                            <RunningIndicator />
                          )}
                        </TextLink>
                      </Column>
                      <Column actions>
                        <Action horizontal to={`/programs/${id}`}>
                          <Icon small name="view" />
                        </Action>
                        <Action horizontal to={`/programs/${id}/edit`}>
                          <Icon small name="edit" />
                        </Action>
                        <Action
                          horizontal
                          onClick={(e) => {
                            onDeleteProgram(id)
                            e.stopPropagation()
                          }}
                        >
                          <Icon small name="delete" />
                        </Action>
                      </Column>
                    </Row>
                  )
                })}
              </Body>
            </Table>
          )
        }}
      </ListToolbar>
    </>
  )
}

export default withRouter(withTranslation()(ProgramList))
