import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'

import { ApiError } from '../../lib/api'
import { programs as programsResource } from '../../redux/actions/resource'
import { getProgramById, getCurrentProjectId } from '../../redux/reducers'

import {
  DateTime,
  FormField,
  StyledForm,
  Dropdown,
} from '../../components/forms'
import Icon from '../../components/Icon'
import Action from '../../components/Action'
import Toolbar from '../../components/Toolbar'

const EditProgram = ({ history, match, t }) => {
  const programId = match.params.id
  const program =
    programId == null
      ? {}
      : useSelector((state) => getProgramById(state, programId))
  const currentProjectId = useSelector(getCurrentProjectId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (programId != null) {
      dispatch(programsResource.read(programId))
    }
  }, [])

  const updateProgram = (id, attributes) =>
    dispatch(programsResource.update(id, undefined, attributes))

  const createProgram = (projectId, attributes) =>
    dispatch(programsResource.create({ projectId }, attributes))

  const { id, name, programType, startsAt, endsAt } = program || {}

  return (
    <>
      <Toolbar title={name || t('attributes:program.newProgram')}>
        <Action form="editProgram">
          <Icon name="confirm" />
        </Action>
      </Toolbar>
      <Form
        initialValues={{ name, programType, startsAt, endsAt }}
        onSubmit={(attributes) => {
          const isCreate = id == null
          const result = isCreate
            ? createProgram(currentProjectId, attributes)
            : updateProgram(id, attributes)
          return result.promise.then((result) => {
            if (result instanceof ApiError) {
              const { errors } = result.json
              const ret = Object.keys(errors).reduce((ret, key) => {
                ret[key] = errors[key].join(', ')
                return ret
              }, {})
              return ret
            } else {
              isCreate
                ? history.push(`/programs`)
                : history.push(`/programs/${id}`)
            }
          })
        }}
      >
        {({ handleSubmit }) => (
          <StyledForm divided id="editProgram" onSubmit={handleSubmit}>
            <FormField name="name" label={t('attributes:program.name')} />
            <FormField
              name="programType"
              label={t('attributes:program.programType')}
              options={t('attributes:program.programTypeOptions')}
              component={Dropdown}
            />
            <FormField
              // required
              name="startsAt"
              label={t('attributes:program.startsAt')}
              component={DateTime}
            />
            <FormField
              // required
              name="endsAt"
              label={t('attributes:program.endsAt')}
              component={DateTime}
            />
          </StyledForm>
        )}
      </Form>
    </>
  )
}

export default withRouter(withTranslation()(EditProgram))
