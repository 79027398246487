import debounce from 'debounce'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { sortBy } from 'lodash'

import {
  dashboard as dashboardResource,
  valves as valvesResource,
} from '../redux/actions/resource'
import {
  setCurrentProject,
  useSwitchProject,
} from '../redux/actions/currentProject'

import {
  getCurrentUser,
  getDashboard,
  getAllValves,
  getAllProjects,
  getCurrentRole,
  getProfile,
} from '../redux/reducers'

import Action from '../components/Action'
import { ClosableSearch, SearchWrapper, CloseIcon } from '../components/forms'
import { Options } from '../components/forms/Dropdown'
import Icon from '../components/Icon'
import SensorValueTile from '../components/sensors/sensor_value_tile/SensorValueTile'
import Table, {
  Body,
  Column,
  Head,
  Header,
  Row,
  Title,
} from '../components/Table'
import Tile, { ActionDivider, Grid, LabelledValue } from '../components/Tile'
import ProgramShiftTable from '../components/composed/ProgramShiftTable'
import WelcomeMessage from '../components/dashboard/WelcomeMessage'

import { SensorList } from '../components/sensors/sensor_value_tile/SensorList'

const Project = styled.div``

const DashboardTile = withRouter(
  withTheme(
    ({
      dashboardProject,
      project,
      summary,
      setProjectAndNavigate,
      history,
      theme,
    }) => {
      const dispatch = useDispatch()
      const { t } = useTranslation()
      const projectId = dashboardProject?.id
      const [isSearching, setSearching] = useState(false)

      const searchFieldRef = useRef(null)

      const valves = useSelector((state) =>
        getAllValves(state, (valve) => valve.projectId === projectId)
      )

      useEffect(() => {
        if (isSearching) {
          dispatch(valvesResource.list({ projectId }))
          searchFieldRef?.current?.focus()
        }
      }, [isSearching, searchFieldRef])

      const onSearch = (search) => {
        dispatch(valvesResource.list({ projectId, search }))
      }
      const [searchParams, updateSearchParams] = useState('')
      const debouncedSearch = useRef(debounce(onSearch, 1000)).current
      const throttleSearch = (e) => {
        const inputValue = e.target.value
        updateSearchParams(inputValue)
        const params = new window.URLSearchParams()
        if (inputValue) {
          params.append('q[name_cont]', inputValue)
          params.append('q[activated_eq]', 'true')
        }
        debouncedSearch(params)
      }

      return (
        <Tile
          alert={
            dashboardProject.hasUnreadError && (
              <Action to="/notifications">
                <Icon
                  name="exclamation-circle"
                  small
                  solid
                  border={theme.colors.gray}
                  background={theme.colors.background}
                  color={theme.colors.alert}
                />
              </Action>
            )
          }
          key={dashboardProject.id}
          title={dashboardProject.name}
          subtitle={t(
            `attributes:user:roleOptions:${dashboardProject.userProjectRole}`
          )}
          image={dashboardProject.backgroundUrl}
          onClick={(e) => {
            if (!isSearching) {
              setProjectAndNavigate(dashboardProject.id)(e)
            }
          }}
          actions={
            isSearching ? (
              <SearchWrapper>
                <CloseIcon
                  onClick={() => {
                    updateSearchParams('')
                    setSearching(false)
                  }}
                />
                <ClosableSearch
                  ref={searchFieldRef}
                  value={searchParams}
                  onChange={throttleSearch}
                />
                <Options open>
                  {(valves || []).map(({ id, name }, index) => (
                    <li key={index}>
                      <a
                        onClick={setProjectAndNavigate(
                          dashboardProject.id,
                          `/valves/${id}`
                        )}
                      >
                        {name}
                      </a>
                    </li>
                  ))}
                </Options>
              </SearchWrapper>
            ) : (
              <>
                <Action
                  onClick={setProjectAndNavigate(dashboardProject.id, '/map')}
                >
                  <Icon name="location" color="#fff" border="#fff" />
                </Action>
                <ActionDivider />
                <Action
                  onClick={(e) => {
                    setSearching(true)
                    e.stopPropagation()
                  }}
                >
                  <Icon name="search" color="#fff" border="#fff" />
                </Action>
              </>
            )
          }
        >
          {project && dashboardProject.userProjectRole !== 'farmer' && (
            <LabelledValue
              value={`${project.centralModulesOnlineCount}/${project.centralModulesCount}`}
              label={t('attributes:project:centralModulesOnlineCount')}
              valueColor={
                project.centralModulesOnlineCount < project.centralModulesCount
                  ? 'red'
                  : null
              }
            />
          )}
          {summary
            .filter(
              (x) =>
                !['central_module', 'watering_line'].includes(x.entity_type)
            )
            .map(({ entity_type, count }, index) => {
              return (
                <LabelledValue
                  key={index}
                  value={count}
                  label={t(`models:${entity_type}`, { count })}
                />
              )
            })}
        </Tile>
      )
    }
  )
)

const Dashboard = () => {
  const { t } = useTranslation()
  const dashboardProjects = useSelector(getDashboard)
  const projects = useSelector(getAllProjects)
  const dispatch = useDispatch()

  // const role = useSelector(getCurrentRole)
  // const isProjectAdmin = role === 'admin'
  const isGlobalAdmin = useSelector(getProfile).admin

  useEffect(() => {
    dispatch(dashboardResource.list())
  }, [dispatch])

  const setProjectAndNavigate = useSwitchProject()

  const rolesThatCanSeeProgramsAndShifts = ['irrigation_maintainer', 'admin']

  return (
    <>
      <WelcomeMessage />
      <Grid>
        {dashboardProjects.map(
          (
            {
              programs = [],
              sensorValues,
              summary,
              valves,
              ...dashboardProject
            },
            index
          ) => {
            return (
              <Project key={index}>
                <DashboardTile
                  {...{
                    dashboardProject,
                    summary,
                    setProjectAndNavigate,
                    project: projects.find((x) => x.id === dashboardProject.id),
                  }}
                />
                <SensorList>
                  {sortBy(sensorValues, ['sortOrder', 'id']).map(
                    (
                      {
                        id,
                        name,
                        unit,
                        wateringModuleId,
                        currentValue: { value, timestamp },
                        thresholds: { min, lower, higher, max },
                      },
                      index
                    ) => (
                      <SensorValueTile
                        id={id}
                        key={index}
                        title={name}
                        value={value}
                        timestamp={timestamp}
                        unit={t(`attributes:sensorValue:unitOptions:${unit}`)}
                        valueMin={min}
                        valueLower={lower}
                        valueHigher={higher}
                        valueMax={max}
                        onClick={setProjectAndNavigate(
                          dashboardProject.id,
                          `/map?moduleId=${wateringModuleId}`
                        )}
                        onEdit={setProjectAndNavigate(
                          dashboardProject.id,
                          `/sensors/${id}/edit`
                        )}
                        onShow={setProjectAndNavigate(
                          dashboardProject.id,
                          `/sensors/${id}`
                        )}
                        onShowOnMap={setProjectAndNavigate(
                          dashboardProject.id,
                          `/map?moduleId=${wateringModuleId}`
                        )}
                        showMenu={isGlobalAdmin}
                      />
                    )
                  )}
                </SensorList>
                {rolesThatCanSeeProgramsAndShifts.includes(
                  dashboardProject.userProjectRole
                ) && (
                  <ProgramShiftTable
                    programs={programs}
                    title={t('routes:dashboard.runningPrograms')}
                    linkToPrograms={true}
                    onNavigate={() =>
                      dispatch(setCurrentProject(dashboardProject.id))
                    }
                  />
                )}
                {dashboardProject.userProjectRole === 'farmer' && (
                  <Table>
                    <Title>Ventile</Title>
                    {(valves || []).map(
                      (
                        {
                          id,
                          name,
                          currentValue,
                          shifts: { running = [], upcoming = [] },
                        },
                        index
                      ) => {
                        return (
                          <Fragment key={index}>
                            <Head>
                              <Row>
                                <Column>
                                  <Icon
                                    small
                                    name="valve"
                                    color={currentValue ? 'blue' : 'black'}
                                  />
                                </Column>
                                <Column colSpan={2}>{name}</Column>
                                <Column actions={1}>
                                  <Action
                                    onClick={setProjectAndNavigate(
                                      dashboardProject.id,
                                      `/valves/${id}`
                                    )}
                                  >
                                    <Icon small name="forward" />
                                  </Action>
                                </Column>
                              </Row>
                            </Head>
                            <Body>
                              {running
                                .map((shift) => ({ ...shift, type: 'running' }))
                                .concat(
                                  upcoming.map((shift) => ({
                                    ...shift,
                                    type: 'upcoming',
                                  }))
                                )
                                .map(
                                  (
                                    {
                                      name,
                                      programName,
                                      currentOccurrenceEndsAt,
                                      nextOccurrenceStartsAt,
                                      type,
                                    },
                                    index
                                  ) => {
                                    return (
                                      <Fragment key={index}>
                                        <Row>
                                          <Column />
                                          <Header colSpan={2}>
                                            {programName}
                                          </Header>
                                          <Column />
                                        </Row>
                                        <Row key={index}>
                                          <Column />
                                          <Column fixed>{name}</Column>
                                          <Column>
                                            {t(`attributes:shift:${type}`, {
                                              currentOccurrenceEndsAt,
                                              nextOccurrenceStartsAt,
                                            })}
                                          </Column>
                                          <Column />
                                        </Row>
                                      </Fragment>
                                    )
                                  }
                                )}
                            </Body>
                          </Fragment>
                        )
                      }
                    )}
                  </Table>
                )}
              </Project>
            )
          }
        )}
      </Grid>
    </>
  )
}

export default Dashboard
