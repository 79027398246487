import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import parseJSON from 'date-fns/parseJSON'

import {
  programPublishAttempts,
  programs as programsResource,
  shifts as shiftsResource,
} from '../../redux/actions/resource'
import { getProgramById, getAllShiftsForProgram } from '../../redux/reducers'

import Action, { Actions } from '../../components/Action'
import { confirm } from '../../components/Alert'
import { Button } from '../../components/forms/buttons'
import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'
import ListToolbar from '../../components/ListToolbar'
import Tile, { Grid, LabelledValue } from '../../components/Tile'
import { TextLink } from '../../components/type'
import ListTitle from '../../components/composed/ListTitle'
import { url } from '../../lib/api'
import { getStoredToken } from '../../lib/auth'
import { addFlashMessage } from "../../redux/actions";

const ProgramShow = ({ history, match, t }) => {
  const programId = match.params.id
  const token = getStoredToken()
  const program = useSelector((state) => getProgramById(state, programId))
  const { name, id, startsAt, endsAt, availableActions, state } = program || {}

  const shifts = useSelector((state) => getAllShiftsForProgram(state, id))
  const dispatch = useDispatch()

  const [pendingWorkflow, setPendingWorkflow] = useState()

  useEffect(() => {
    if (programId) {
      dispatch(programsResource.read(programId))
      dispatch(shiftsResource.list({ programId }))
    }
  }, [dispatch])

  const onSearch = (search) => {
    if (programId) {
      dispatch(shiftsResource.list({ programId, search }))
    }
  }

  const publishProgramm = (workflow) => {
    setPendingWorkflow(workflow)
    dispatch(
      programPublishAttempts.create({ programId }, { workflow, wait: 0 })
    )
  }

  const onDeleteProgram = async () => {
    if (await confirm('Möchtest du dieses Programm wirklich löschen')) {
      dispatch(programsResource.destroy(programId)).promise.then(() => {
        history.push('/programs')
      })
    }
  }

  const onDeleteShift = async (id) => {
    if (await confirm('Möchtest du diesen Turnus wirklich löschen?')) {
      dispatch(shiftsResource.destroy(id))
    }
  }

  const onDuplicateProgram = async (id) => {

    try {
      const response = await fetch(`${url}/programs/${id}/duplicate`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        dispatch(
          addFlashMessage('Fehler', t('errors:program:couldNotDuplicate'))
        )
      } else {
        await response.json()
        history.push('/programs')
      }
    } catch (error) {
      console.log('Error duplicating program')
    }
  }

  return (
    <>
      <Toolbar title={name} onBack={() => history.push('/programs')}>
        <Action to={`/programs/${programId}/edit`}>
          <Icon name="edit" />
        </Action>
        <Button onClick={() => onDuplicateProgram(programId)}>
          <div>{t('duplicate')}</div>
        </Button>
      </Toolbar>
      <Table attributes>
        <Body>
          <Row>
            <Header scope="row">{t('attributes:program.name')}:</Header>
            <Column>{name}</Column>
          </Row>
          <Row>
            <Header scope="row">{t('attributes:program.startsAt')}:</Header>
            <Column>
              {t('format:dateTimeLong', { date: parseJSON(startsAt) })}
            </Column>
          </Row>
          <Row>
            <Header scope="row">{t('attributes:program.endsAt')}:</Header>
            <Column>
              {t('format:dateTimeLong', { date: parseJSON(endsAt) })}
            </Column>
          </Row>
          <Row>
            <Header scope="row">{t('attributes:program.state')}:</Header>
            <Column>{t(`attributes:program.states.${state}`)}</Column>
          </Row>
          <Row>
            <Column colSpan={2}>
              <Actions>
                {Object.keys(availableActions || {})
                  .filter((key) => availableActions[key])
                  .map((workflow) => (
                    <Button
                      key={workflow}
                      onClick={() => publishProgramm(workflow)}
                      waiting={pendingWorkflow === workflow}
                    >
                      {t(`actions:program.${workflow}`)}
                    </Button>
                  ))}
              </Actions>
            </Column>
          </Row>
        </Body>
      </Table>
      <ListTitle>
        <span>{t('models:shift', { count: 0 })}</span>
        <Action to={`/programs/${programId}/shifts/new`}>
          <Icon small name="add" />
        </Action>
      </ListTitle>
      <ListToolbar onSearch={onSearch}>
        {({ showTiles }) => {
          return showTiles ? (
            <Grid>
              {shifts.map(({ name, id, startsAt, endsAt, summary }, index) => {
                return (
                  <Tile
                    key={index}
                    title={name}
                    onClick={() => history.push(`/shifts/${id}`)}
                    footer={`${t('format:dateTimeShort', {
                      date: parseJSON(startsAt),
                    })} - ${t('format:dateTimeShort', {
                      date: parseJSON(endsAt),
                    })}`}
                    centered
                    actions={
                      <Action
                        onClick={(e) => {
                          onDeleteShift(id)
                          e.stopPropagation()
                        }}
                      >
                        <Icon name="delete" color="#fff" border="#fff" />
                      </Action>
                    }
                  >
                    {(summary || []).map(
                      ({ entity_type, scope, count }, index) => {
                        // build translation key by summary entry
                        let txtKey = null
                        if (scope == null) {
                          txtKey = `models:${entity_type}`
                        } else if (scope == 'unsuspended') {
                          txtKey = `attributes:${entity_type}:modes:0`
                        } else if (scope == 'suspended') {
                          txtKey = `attributes:${entity_type}:modes:1`
                        } else if (scope == 'with_sensor_based_irrigation') {
                          txtKey = `attributes:${entity_type}:modes:2`
                        }

                        return (
                          <LabelledValue
                            key={index}
                            value={count}
                            label={t(txtKey, { count: count })}
                          />
                        )
                      }
                    )}
                  </Tile>
                )
              })}
            </Grid>
          ) : (
            <Table spacious divided>
              <Head>
                <Row>
                  <Header colSpan={2}>{t('models:shift', { count: 0 })}</Header>
                </Row>
              </Head>
              <Body>
                {shifts.map(({ name, id }, index) => {
                  return (
                    <Row key={index}>
                      <Column>
                        <TextLink to={`/shifts/${id}`}>{name}</TextLink>
                      </Column>
                      <Column actions>
                        <Action horizontal to={`/shifts/${id}`}>
                          <Icon small name="view" />
                        </Action>
                        <Action horizontal to={`/shifts/${id}/edit`}>
                          <Icon small name="edit" />
                        </Action>
                        <Action
                          horizontal
                          onClick={(e) => {
                            onDeleteShift(id)
                            e.stopPropagation()
                          }}
                        >
                          <Icon small name="delete" />
                        </Action>
                      </Column>
                    </Row>
                  )
                })}
              </Body>
            </Table>
          )
        }}
      </ListToolbar>
      <Grid>
        <Action to={`/programs/${programId}/shifts/new`}>
          <Icon name="add" />
        </Action>
      </Grid>
    </>
  )
}

export default withRouter(withTranslation()(withTheme(ProgramShow)))
