import { put, take } from 'redux-saga/effects'

import { SET_CURRENT_PROJECT } from '../actions/currentProject'
import {
  modules,
  programs,
  sensorValues,
  valves,
  search,
} from '../actions/resource'

export default function* projectSwitchingFlow() {
  while (true) {
    // detect project switch
    const { payload: projectId } = yield take(SET_CURRENT_PROJECT)
    // reload project scoped resources
    yield put(programs.list({ projectId }))
    yield put(modules.list({ projectId }))
    yield put(sensorValues.list({ projectId }))
    yield put(valves.list({ projectId }))
    yield put(search.list({ projectId }))
  }
}
