import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import Action from '../../components/Action'
import Icon from '../../components/Icon'
import ListToolbar from '../../components/ListToolbar'
import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import Tile, { Grid, GridItem, LabelledValue } from '../../components/Tile'
import { TextLink } from '../../components/type'

const ValveList = ({
  children,
  valves,
  onSearch,
  onTileClick,
  tileActions,
  t,
}) => {
  return (
    <ListToolbar onSearch={onSearch}>
      {({ showTiles }) => {
        return showTiles ? (
          <Grid>
            {(valves || []).map(
              (
                {
                  id,
                  name,
                  currentIsSuspended,
                  sensorBasedIrrigationEnabled,
                  currentValue,
                },
                index
              ) => {
                // 0 = Auto; 1 = Manual; 2 = Demand Driven
                let modeIndex = null
                if (currentIsSuspended) {
                  modeIndex = 1
                } else if (!currentIsSuspended) {
                  if (sensorBasedIrrigationEnabled) {
                    modeIndex = 2
                  } else {
                    modeIndex = 0
                  }
                } else {
                  // fall back to Auto
                  modeIndex = 0
                }

                // only show current value (state) if its value is known
                const showCurrentValue = currentValue == 0 || currentValue == 1

                return (
                  <GridItem key={index}>
                    <Tile
                      onClick={() => onTileClick(id)}
                      title={name}
                      actions={tileActions ? tileActions(id) : undefined}
                    >
                      <LabelledValue
                        value={t(`attributes:valve.modes_short.${modeIndex}`)}
                        label={t('attributes:valve.mode')}
                      />
                      {showCurrentValue && (
                        <LabelledValue
                          value={t(
                            `attributes:valve.states_short.${currentValue}`
                          )}
                          label={t('attributes:valve.state')}
                        />
                      )}
                    </Tile>
                  </GridItem>
                )
              }
            )}
          </Grid>
        ) : (
          <Table spacious divided>
            <Head>
              <Row>
                <Header colSpan={2}>{t('models:valve', { count: 0 })}</Header>
              </Row>
            </Head>
            <Body>
              {(valves || []).map(({ id, name }, index) => {
                return (
                  <Row key={index}>
                    <Column>
                      <TextLink to={`/valves/${id}`}>{name}</TextLink>
                    </Column>
                    <Column actions>{children(id)}</Column>
                  </Row>
                )
              })}
            </Body>
          </Table>
        )
      }}
    </ListToolbar>
  )
}

ValveList.propTypes = {
  valves: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSearch: PropTypes.func.isRequired,
  onTileClick: PropTypes.func.isRequired,
  tileActions: PropTypes.func,
  children: PropTypes.func.isRequired,
}

export default withTranslation()(ValveList)
