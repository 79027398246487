import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'

import { modules as modulesResource } from '../../redux/actions/resource'
import { getAllModules, getModuleById } from '../../redux/reducers'
import { CoordinatePicker } from '../../components/forms/CoordinatePicker'

import Action from '../../components/Action'
import {
  Dropdown,
  FormToggle,
  StyledForm,
  FormField,
  ModuleSearchField,
} from '../../components/forms'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'

const EditModule = ({ history, match, t }) => {
  const moduleId = match.params.id
  const module = useSelector((state) => getModuleById(state, moduleId))
  const dispatch = useDispatch()

  useEffect(() => {
    if (moduleId != null) {
      dispatch(modulesResource.read(moduleId))
    }
  }, [dispatch])

  const updateModule = (attributes) =>
    dispatch(modulesResource.update(moduleId, undefined, attributes))

  return (
    <>
      <Toolbar title={module.name} onBack={() => history.goBack()}>
        <Action form="editProgram">
          <Icon name="confirm" />
        </Action>
      </Toolbar>
      {module.type === 'CentralModule' ? (
        <CentralModuleEdit module={module} updateModule={updateModule} />
      ) : (
        <FieldModuleEdit module={module} updateModule={updateModule} />
      )}
    </>
  )
}
export default withRouter(withTranslation()(EditModule))

/**
 * Find modules that can be repeaters, eg not the module itself and no module that has the current module as a repeater.
 * @param {Integer} moduleId
 * @returns {Object} A list of possible modules
 */
const findPossibleRepeaters = (moduleId) => ({
  id,
  repeaterId,
  repeaterEnabled,
}) => repeaterEnabled && moduleId !== id && moduleId !== repeaterId

const FieldModuleEdit = withRouter(
  withTranslation()(({ t, history, module, updateModule }) => {
    const cmWateringModuleId = module.wateringModuleId
    const {
      id,
      projectId,
      repeaterId,
      name,
      location,
      lat,
      lng,
      position,
      onlineStatusNotificationEnabled,
      wateringModuleId,
      repeaterEnabled,
    } = module || {}
    const relatedCentralModule = useSelector((state) =>
      getModuleById(state, cmWateringModuleId)
    )
    const repeaterModules = useSelector((state) =>
      getAllModules(state, findPossibleRepeaters(id))
    )
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(modulesResource.read(cmWateringModuleId))
      dispatch(modulesResource.read(repeaterId))
    }, [dispatch])

    useEffect(() => {
      if (projectId != null) {
        dispatch(modulesResource.list({ projectId }))
      }
    }, [dispatch, projectId])

    console.log("Position from API", position, location)

    return (
      <>
        <Form
          initialValues={{
            name,
            location,
            onlineStatusNotificationEnabled,
            repeaterEnabled,
            wateringModuleId,
            repeaterId,
          }}
          onSubmit={(attributes) => {
            updateModule(attributes).promise.then(() => {
              history.goBack()
            })
          }}
        >
          {({ handleSubmit }) => (
            <StyledForm divided id="editProgram" onSubmit={handleSubmit}>
              <FormField name="name" label={t('attributes:module.name')} />
              <FormField
                name="wateringModuleId"
                label={t('models:central_module')}
                module={relatedCentralModule}
                component={ModuleSearchField}
              />
              <FormField
                name="onlineStatusNotificationEnabled"
                noLine
                label={t('attributes:module.notification_60_minutes')}
                component={FormToggle}
              />
              <FormField
                name="repeaterEnabled"
                noLine
                label={t('attributes:module.repeaterFunction')}
                component={FormToggle}
              />
              <FormField
                name="repeaterId"
                label={t('attributes:module.repeater')}
                allowNull
                options={[
                  { id: null, name: 'Kein Repeater' },
                  ...repeaterModules,
                ]}
                component={Dropdown}
              />
              {/*<FormField name="lat" label={t('attributes:module.lat')} />*/}
              {/*<FormField name="lng" label={t('attributes:module.lng')} />*/}
              <CoordinatePicker
                label={t('coordinatePicker:location')}
                placeholder={t('coordinatePicker:placeholder')}
                name={'location'}
                lat={lat}
                lng={lng}
                initialZoomWithoutPosition={6}
                initialCoordinatesWithoutPosition={{ lat: 47.9753, lng: 10.4295 }}
              />
            </StyledForm>
          )}
        </Form>
      </>
    )
  })
)

const CentralModuleEdit = withRouter(
  withTranslation()(({ t, history, module, updateModule }) => {
    const { name, online_status_notifications_enabled } = module
    return (
      <Form
        initialValues={{ name, online_status_notifications_enabled }}
        onSubmit={(attributes) => {
          updateModule(attributes).promise.then(() => {
            history.goBack()
          })
        }}
      >
        {({ handleSubmit }) => (
          <StyledForm divided id="editProgram" onSubmit={handleSubmit}>
            <FormField name="name" label={t('attributes:module.name')} />
            <FormField
              name="online_status_notifications_enabled"
              noLine
              label={t('attributes:module.notification_online_status')}
              component={FormToggle}
            />
          </StyledForm>
        )}
      </Form>
    )
  })
)
