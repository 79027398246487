import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Show from './Show'
import Edit from './Edit'

export default () => (
  <Switch>
    <Route path="/profile" exact component={Show} />
    <Route path="/profile/edit" exact component={Edit} />
  </Switch>
)
