import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Form } from 'react-final-form'

import {
  valveGroups as valveGroupsResource,
  shifts as shiftsResource,
} from '../../redux/actions/resource'
import { getValveGroupById, getShiftById } from '../../redux/reducers'

import {
  FormField,
  StyledForm,
  FormRow,
  RowSpace,
} from '../../components/forms'
import Icon from '../../components/Icon'
import Action from '../../components/Action'
import Toolbar from '../../components/Toolbar'

const EditValveGroups = ({ history, match, t }) => {
  const valveGroupId = match.params.id
  const shiftId = match.params.shiftId
  const valveGroup =
    valveGroupId == null
      ? {}
      : useSelector((state) => getValveGroupById(state, valveGroupId))

  const shift =
    shiftId == null ? {} : useSelector((state) => getShiftById(state, shiftId))
  const programId = shift?.programId
  const dispatch = useDispatch()

  useEffect(() => {
    if (valveGroupId != null) {
      dispatch(valveGroupsResource.read(valveGroupId))
    }
  }, [dispatch, valveGroupId])

  useEffect(() => {
    if (shiftId != null) {
      dispatch(shiftsResource.read(shiftId))
    }
  }, [dispatch, shiftId])

  const updateValveGroup = (id, attributes) =>
    dispatch(valveGroupsResource.update(id, undefined, attributes))

  const createValveGroup = (attributes) =>
    dispatch(
      shiftsResource.update(shiftId, undefined, {
        shift: {
          valveGroupsAttributes: [{ ...attributes, programId }],
        },
      })
    )

  const { name, id } = valveGroup || {}

  return (
    <>
      <Toolbar title={name != null ? name : 'Neue Valve Group'}>
        <Action form="editValveGroup">
          <Icon name="confirm" />
        </Action>
      </Toolbar>
      <Form
        initialValues={valveGroup}
        onSubmit={(attributes) =>
          (id == null
            ? createValveGroup(attributes)
            : updateValveGroup(id, attributes)
          ).promise.then(() => {
            history.goBack()
          })
        }
      >
        {({ handleSubmit, values }) => {
          const { duration, wateringCoefficient } = values
          return (
            <StyledForm divided id="editValveGroup" onSubmit={handleSubmit}>
              <FormField name="name" label={t('attributes:valveGroup.name')} />
              <FormRow>
                <FormField
                  name="duration"
                  type="number"
                  label={t('attributes:valveGroup.duration')}
                  rowWidth="40%"
                />
                <RowSpace rowWidth="10%" />
                <FormField
                  name="wateringCoefficient"
                  type="number"
                  step="0.1"
                  label={t('attributes:valveGroup.wateringCoefficient')}
                  rowWidth="50%"
                />
              </FormRow>
              <p>{`${t('attributes:valveGroup.totalDuration')}: ${t(
                'format:minutes',
                {
                  count: (duration || 0) * (wateringCoefficient || 1),
                }
              )}`}</p>
            </StyledForm>
          )
        }}
      </Form>
    </>
  )
}

export default withRouter(withTranslation()(withTheme(EditValveGroups)))
