import { FormField, ValidationError } from '../../../components/forms'
import { thresh0Label, thresh100Label } from './utils'
import React from 'react'

export const MultiThresholdIrrigationFields = ({
  errors,
  touched,
  t,
  sensorUnit,
}) => {
  return (
    <>
      <p>{t('attributes:valve:sensorBasedIrrigation.hints.thresh0')}</p>
      <FormField
        name="sensorBasedIrrigationAttributes[thresh0]"
        label={thresh0Label(sensorUnit, t)}
      />
      {errors.sensorBasedIrrigationAttributes &&
        errors.sensorBasedIrrigationAttributes.thresh0 &&
        touched['sensorBasedIrrigationAttributes[thresh0]'] && (
          <div styles="column-span: all;">
            <ValidationError>
              {errors.sensorBasedIrrigationAttributes.thresh0}
            </ValidationError>
          </div>
        )}
      <p>
        {t('attributes:valve:sensorBasedIrrigation.hints.thresh100Paragraph1')}
      </p>
      <p>
        {t('attributes:valve:sensorBasedIrrigation.hints.thresh100Paragraph2')}
      </p>
      <FormField
        name="sensorBasedIrrigationAttributes[thresh100]"
        label={thresh100Label(sensorUnit, t)}
      />
      {errors.sensorBasedIrrigationAttributes &&
        errors.sensorBasedIrrigationAttributes.thresh100 &&
        touched['sensorBasedIrrigationAttributes[thresh100]'] && (
          <div styles="column-span: all;">
            <ValidationError>
              {errors.sensorBasedIrrigationAttributes.thresh100}
            </ValidationError>
          </div>
        )}
    </>
  )
}

export default MultiThresholdIrrigationFields
