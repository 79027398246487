export const byValveId = (id) => (state) => {
  if (id === null || id === undefined) {
    return {}
  }
  const allSuspensions = state.resources.temporary_suspensions || []
  const suspensionsOfValve = Object.values(allSuspensions).filter(
    (x) => x?.valveId?.toString() === id.toString()
  )
  return suspensionsOfValve[0]
}
