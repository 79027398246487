import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import styled from 'styled-components'

import { modules as modulesResource } from '../../redux/actions/resource'
import {
  getModuleById,
  getAllModules,
  getCurrentProject,
} from '../../redux/reducers'

import Action from '../../components/Action'
import { Button } from '../../components/forms/buttons'
import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'
import ListToolbar from '../../components/ListToolbar'
import ListTitle from '../../components/composed/ListTitle'
import { H1 } from '../../components/type'
import ModuleListPart from './ModuleListPart'
import { confirm } from '../../components/Alert'
import TagListItem from '../../components/TagListItem'
import { Grid } from '../../components/Tile'
import ValveList from './ValveList'
import SensorList from './SensorList'

export const VerticalSpace = styled.div`
  margin-bottom: 4rem;
`

const Show = ({ history, match, t }) => {
  const moduleId = match.params.id
  const module = useSelector((state) => getModuleById(state, moduleId))

  const dispatch = useDispatch()

  useEffect(() => {
    if (moduleId) {
      dispatch(modulesResource.read(moduleId))
    }
  }, [dispatch])

  const onDeleteModule = async (id) => {
    if (await confirm('Möchtest du dieses Modul wirklich löschen?')) {
      dispatch(modulesResource.destroy(id))
    }
  }

  return (
    <>
      <Toolbar title={module?.name} onBack={() => history.goBack()}>
        <Action to={`/modules/${moduleId}/edit`}>
          <Icon name="edit" />
        </Action>
      </Toolbar>
      {module?.type === 'CentralModule' ? (
        <CentralModuleShow
          history={history}
          module={module}
          onDeleteModule={onDeleteModule}
        />
      ) : (
        <FieldModuleShow
          history={history}
          module={module}
          onDeleteModule={onDeleteModule}
        />
      )}
    </>
  )
}

export default withRouter(withTranslation()(withTheme(Show)))

const FieldModuleShow = withTranslation()(
  ({ history, module, t, onDeleteModule }) => {
    const cmWateringModuleId = module?.wateringModuleId
    const relatedCentralModule = useSelector((state) =>
      getModuleById(state, cmWateringModuleId)
    )

    const repeaterClients = useSelector((state) =>
      getAllModules(state, ({ repeaterId }) => repeaterId === module.id)
    )

    const dispatch = useDispatch()

    const { name, serialNumber, projectId } = module || {}

    useEffect(() => {
      dispatch(modulesResource.read(cmWateringModuleId))
    }, [dispatch])

    useEffect(() => {
      if (projectId != null) {
        dispatch(modulesResource.list({ projectId }))
      }
    }, [dispatch, projectId])

    return (
      <>
        <Table attributes>
          <Body>
            <Row>
              <Header scope="row">{t('attributes:module.name')}:</Header>
              <Column>{name}</Column>
            </Row>
            <Row>
              <Header scope="row">
                {t('attributes:module.serialNumber')}:
              </Header>
              <Column>{serialNumber}</Column>
            </Row>
            <Row>
              <Header scope="row">{t('models:central_module')}:</Header>
              <Column>{relatedCentralModule?.name}</Column>
            </Row>
          </Body>
        </Table>
        <H1> {t(`attributes:module.repeaterClients`)} </H1>
        <Grid smallGap>
          {repeaterClients.map(({ name, serialNumber }, index) => (
            <TagListItem key={index} text={`${name} (${serialNumber})`} />
          ))}
        </Grid>
        <VerticalSpace />
        <H1> {t(`attributes:module.service`)} </H1>
        <Button
          autoSize
          onClick={() => history.push(`/modules/${module.id}/replace`)}
        >
          {t(`actions:module.swapModule`)}
        </Button>
        <VerticalSpace />
        <ValveList moduleId={module?.id} showTiles />
        <SensorList moduleId={module?.id} showTiles />
      </>
    )
  }
)

const CentralModuleShow = withTranslation()(
  ({ history, module, t, onDeleteModule }) => {
    const relatedFieldModules = useSelector(getAllModules).filter(
      ({ wateringModuleId }) => wateringModuleId === module?.id
    )
    const [shownFieldmodules, setShownFieldModules] = useState(
      relatedFieldModules
    )
    const currentProject = useSelector(getCurrentProject)
    const projectId = currentProject?.id
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(modulesResource.list({ projectId }))
    }, [dispatch])

    const onSearch = (search) => {
      let params = new URLSearchParams(search)
      let searchedString = params.get('q[name_cont]')
      setShownFieldModules(
        relatedFieldModules.filter(({ name }) =>
          name.toLowerCase().includes((searchedString || '').toLowerCase())
        )
      )
    }

    const { name } = module || {}
    return (
      <>
        <Table attributes>
          <Body>
            <Row>
              <Header scope="row">{t('attributes:module.name')}:</Header>
              <Column>{name}</Column>
            </Row>
          </Body>
        </Table>
        <H1> {t(`attributes:module.service`)} </H1>
        <Button
          autoSize
          onClick={() => history.push(`/modules/${module.id}/replace`)}
        >
          {t(`actions:module.swapModule`)}
        </Button>
        <VerticalSpace />
        <ListTitle>
          <span>{t('models:fieldModule', { count: 0 })}</span>
        </ListTitle>
        <ListToolbar onSearch={onSearch}>
          {({ showTiles }) => {
            return (
              <ModuleListPart
                values={shownFieldmodules}
                showTiles={showTiles}
                onDelete={onDeleteModule}
                itemType="FieldModule"
              />
            )
          }}
        </ListToolbar>
      </>
    )
  }
)
