import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import {
  notifications as notificationsResource,
  projects as projectsResource,
} from '../../redux/actions/resource'

import {
  getAllNotifications,
  getAllProjects,
  getCollectionMeta,
} from '../../redux/reducers'
import Action from '../../components/Action'
import { confirm } from '../../components/Alert'
import Dropdown, { Options } from '../../components/forms/Dropdown'
import { FlexContainer } from '../../components/Layout/flex'
import Icon from '../../components/Icon'
import StyledNotificationItem from '../../components/Notification'
import Pagination from '../../components/Pagination'
import Toolbar from '../../components/Toolbar'
import TabBar from '../../components/TabBar'

const DropdownWrapper = styled.div`
  position: relative;

  ${Options} {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.inactive};
  }
`

const NotificationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1rem -1rem;
`

const lastPart = (string) => {
  const parts = string.toString().split(':')
  return parts[parts.length - 1]
}

const List = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [severity, setSeverity] = useState('42')
  const [readAt, setReadAt] = useState('1')
  const [currentProjectId, setCurrentProjectId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const notifications = useSelector(getAllNotifications)
  const projects = useSelector(getAllProjects)
  const totalPages = useSelector((state) =>
    getCollectionMeta(state, 'notifications', 'totalPages')
  )

  const submitSearch = () => {
    const search = new window.URLSearchParams()

    if (severity != '42') {
      search.append('q[severity_eq]', severity)
    }
    if (readAt != '42') {
      search.append('q[read_at_not_null]', !+readAt)
    }
    if (currentProjectId != null) {
      search.append('q[project_id_eq]', currentProjectId)
    }

    search.append('per_page', 10)
    search.append('page', currentPage)

    dispatch(notificationsResource.list({ search }))
  }

  const markAsRead = (id) =>
    dispatch(notificationsResource.update(id)).promise.then(submitSearch)
  const markAllAsRead = async () => {
    if (
      await confirm(
        'Möchtest du wirklich alle Benachrichtigungen als gelesen markieren?'
      )
    ) {
      dispatch(
        notificationsResource.custom(
          'mark_all_as_read',
          'POST',
          {},
          {
            markUntil: notifications[0]?.createdAt,
          }
        )
      ).promise.then(submitSearch)
    }
  }

  useEffect(() => {
    dispatch(projectsResource.list())
  }, [dispatch])

  useEffect(() => {
    submitSearch()
  }, [dispatch, severity, readAt, currentProjectId, currentPage])

  const updateReadAt = (i18n) => {
    setCurrentPage(1)
    setReadAt(lastPart(i18n))
  }

  const updateSeverity = (i18n) => {
    setCurrentPage(1)
    setSeverity(lastPart(i18n))
  }

  return (
    <>
      <Toolbar
        title="Benachrichtigungen"
        onBack={() => history.push('/dashboard')}
      >
        <Action to="/profile/edit">
          <Icon name="cog" />
        </Action>
      </Toolbar>

      <div>
        <DropdownWrapper>
          <Dropdown
            formField
            options={[{ id: null, name: 'Alle Projekte' }, ...projects]}
            selected={currentProjectId}
            onChange={setCurrentProjectId}
          />
        </DropdownWrapper>
        <FlexContainer distributed alignCenter>
          <TabBar
            labels={[
              'attributes:notification:readAtOptions:0',
              'attributes:notification:readAtOptions:1',
              'attributes:notification:readAtOptions:42',
            ]}
            active={`attributes:notification:readAtOptions:${readAt}`}
            onChange={updateReadAt}
            translate={true}
          />

          <Action onClick={markAllAsRead}>
            <Icon name="envelope-open" />
          </Action>
        </FlexContainer>

        <TabBar
          fullWidth
          translate
          labels={[
            'attributes:notification:severityOptions:0',
            'attributes:notification:severityOptions:1',
            'attributes:notification:severityOptions:2',
            'attributes:notification:severityOptions:42',
          ]}
          active={`attributes:notification:severityOptions:${severity}`}
          onChange={updateSeverity}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages || 1}
        onPaginate={setCurrentPage}
      />
      <NotificationList>
        {notifications.map(
          (
            {
              id,
              title,
              description,
              readAt,
              createdAt,
              actionUrl,
              actionName,
              severity,
              projectId,
            },
            index
          ) => (
            <StyledNotificationItem
              id={id}
              title={title}
              description={description}
              key={index}
              isRead={!!readAt}
              time={moment(createdAt).format('HH:mm:ss')}
              date={moment(createdAt).format('YYYY-MM-DD')}
              severity={severity}
              actionUrl={actionUrl}
              actionName={actionName}
              markAsReadHandler={(_) => markAsRead(id)}
              projectId={projectId}
            />
          )
        )}
      </NotificationList>
    </>
  )
}

export default List
