import { all } from 'redux-saga/effects'

import authorization from './authorization'
import cable from './cable'
import currentProject from './currentProject'
import resource from './resource'
import webPush from './webPush'
import changeLanguage from './i18n'

import * as auth from '../../lib/auth'
import * as api from '../../lib/api'

export default function* rootSaga() {
  yield all([
    authorization(auth),
    cable(),
    currentProject(),
    resource(api),
    webPush(api),
    changeLanguage(),
  ])
}
