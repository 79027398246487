import React from 'react'
import { withTranslation } from 'react-i18next'

import { H2 } from '../components/type'

const Events = ({ t }) => (
  <>
    <H2>{t('models:event', { count: 0 })}</H2>
  </>
)

export default withTranslation()(Events)
