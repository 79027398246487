import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { shifts, valveGroups } from '../../redux/actions/resource'

import { arrayFilter, getAllValveGroups } from '../../redux/reducers'

import { confirm } from '../../components/Alert'
import Action from '../../components/Action'
import Icon from '../../components/Icon'
import Tile, { Grid, GridItem } from '../../components/Tile'
import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import { TextLink } from '../../components/type'
import ListTitle from '../../components/composed/ListTitle'
import ListToolbar from '../../components/ListToolbar'

const ValveGroupListItem = withRouter(
  ({ id, name, onDeleteValveGroup, history }) => {
    return (
      <GridItem>
        <Tile
          title={name}
          onClick={() => history.push(`/valve_groups/${id}`)}
          centered
          reversed
          actions={
            <Action
              onClick={(e) => {
                onDeleteValveGroup(id)
                e.stopPropagation()
              }}
            >
              <Icon name="delete" color="#fff" border="#fff" />
            </Action>
          }
        ></Tile>
      </GridItem>
    )
  }
)

const ValveGroupList = ({ shift, t }) => {
  const shiftId = shift?.id
  const { programId, valveGroupIds } = shift || {}
  const allValveGroups = useSelector((state) =>
    getAllValveGroups(state, arrayFilter(valveGroupIds))
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (programId) {
      dispatch(valveGroups.list({ shiftId, programId }))
    }
  }, [dispatch, programId])

  const onSearch = (search) => {
    if (shift) {
      dispatch(valveGroups.list({ shiftId, search, programId }))
    }
  }

  const onDeleteValveGroup = async (valveGroupId) => {
    if (await confirm('Möchtest du diese Ventilgruppe wirklich löschen?')) {
      const updatedValveGroupIds = valveGroupIds.filter(
        (id) => id != valveGroupId
      )
      dispatch(
        shifts.update(shiftId, undefined, {
          shift: {
            valveGroupIds: [...updatedValveGroupIds],
          },
        })
      )
    }
  }

  return (
    <>
      <ListTitle>
        <span>{t('models:valveGroup', { count: 0 })}</span>
        <Action to={`/shifts/${shiftId}/valve_groups/new`}>
          <Icon small name="add" />
        </Action>
      </ListTitle>
      <ListToolbar onSearch={onSearch}>
        {({ showTiles }) => {
          return showTiles ? (
            <Grid>
              {allValveGroups.map((valveGroup, index) => {
                return (
                  <ValveGroupListItem
                    key={index}
                    {...valveGroup}
                    onDeleteValveGroup={onDeleteValveGroup}
                    currentShiftId={shift?.id}
                    t={t}
                  />
                )
              })}
            </Grid>
          ) : (
            <Table spacious divided>
              <Head>
                <Row>
                  <Header colSpan={2}>
                    {t('models:valveGroup', { count: 0 })}
                  </Header>
                </Row>
              </Head>
              <Body>
                {allValveGroups.map(({ id, name }, index) => {
                  return (
                    <Row key={index}>
                      <Column>
                        <TextLink to={`/valve_groups/${id}`}>{name}</TextLink>
                      </Column>
                      <Column actions>
                        <Action horizontal to={`/valve_groups/${id}`}>
                          <Icon small name="view" />
                        </Action>
                        <Action horizontal to={`/valve_groups/${id}/edit`}>
                          <Icon small name="edit" />
                        </Action>
                        <Action
                          horizontal
                          onClick={() => onDeleteValveGroup(id)}
                        >
                          <Icon small name="delete" />
                        </Action>
                      </Column>
                    </Row>
                  )
                })}
              </Body>
            </Table>
          )
        }}
      </ListToolbar>
    </>
  )
}

export default withTranslation()(ValveGroupList)
