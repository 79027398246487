import subDays from 'date-fns/subDays'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import {
  modules as modulesResource,
  valves as valvesResource,
  sensorValues as sensorValuesResource,
} from '../../redux/actions/resource'
import {
  getModuleById,
  getCurrentProjectId,
  getAllValves,
  getAllSensorValues,
  getCurrentRole,
} from '../../redux/reducers'

import Action from '../../components/Action'
import Icon, { Container as IconContainer } from '../../components/Icon'
import LineChart from '../../components/admin/Charts/components/LineChart'
import TabBar from '../../components/TabBar'
import Table, { Body, Column, Row, Title } from '../../components/Table'
import { H2 } from '../../components/type'
import { url } from '../../lib/api'
import { getStoredToken } from '../../lib/auth'
import { formatValue } from '../../components/sensors/sensor_value_tile/helpers'

const IndicatorContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  & > ${IconContainer} {
    position: absolute;
  }
`

const LinkColumn = styled(Column)`
  cursor: pointer;
`

const TableContainer = styled.div`
  max-width: 400px;
`

const CurrentSensorValueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SelectedModule = styled.div`
  margin-top: 0.5rem;
`

const SignalIndicator = withTheme(({ percentage, theme }) => {
  const indicator = Math.ceil(percentage / 25)
  return indicator > 0 ? (
    <IndicatorContainer>
      <Icon name="signal-4" small bordered={false} color={theme.colors.gray} />
      <Icon name={`signal-${indicator}`} small bordered={false} />
    </IndicatorContainer>
  ) : null
})

const ChartContainer = styled.div`
  margin: 1rem 0;
`

const Chart = ({ id, name }) => {
  const [dateRange, setDateRange] = useState('Tag')

  const [startDate, endDate] = (() => {
    const now = new Date()
    switch (dateRange) {
      case 'Tag':
        return [subDays(now, 1), now]
      case 'Woche':
        return [subDays(now, 7), now]
      case 'Monat':
        return [subDays(now, 30), now]
    }
  })()

  return (
    <ChartContainer>
      {name}
      <LineChart
        startDate={startDate}
        endDate={endDate}
        dataUrl={`${url}/charts/line?sensor_value_id=${id}`}
        token={getStoredToken()}
      />
      <TabBar
        fullWidth
        labels={['Tag', 'Woche', 'Monat']}
        active={dateRange}
        onChange={setDateRange}
      />
    </ChartContainer>
  )
}

const ModuleOverlay = ({ moduleId, onClose, theme }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const navigateToValve = (valveId) => (e) => {
    history.push(`/valves/${valveId}`)
    e.stopPropagation()
  }

  const navigateToNotifications = (e) => {
    history.push(`/notifications`)
    e.stopPropagation()
  }

  const filterByModule = ({ wateringModuleId }) => wateringModuleId === moduleId
  const projectId = useSelector(getCurrentProjectId)
  const module = useSelector((state) => getModuleById(state, moduleId))
  const valves = useSelector((state) => getAllValves(state, filterByModule))
  const sensorValues = useSelector((state) =>
    getAllSensorValues(state, filterByModule)
  )
  const role = useSelector(getCurrentRole)
  const canEditFieldModule =
    role === 'irrigation_maintainer' || role === 'admin'

  const loadModule = () => {
    if (moduleId != null) {
      dispatch(modulesResource.read(moduleId))
      dispatch(valvesResource.list({ projectId }))
      dispatch(sensorValuesResource.list({ projectId }))
    }
  }

  const [sensorCharts, setSensorCharts] = useState({})

  const toggleChart = (sensorId) => {
    setSensorCharts(
      Object.assign({}, sensorCharts, { [sensorId]: !sensorCharts[sensorId] })
    )
  }

  useEffect(() => {
    loadModule()
  }, [moduleId])

  const { id, name, hasUnreadError, signalStrengthPct } = module || {}

  const hasSensorValues = (sensorValues || []).length > 0
  const hasValves = (valves || []).length > 0

  return (
    <SelectedModule>
      <Table>
        <Body>
          <Row>
            <LinkColumn onClick={navigateToNotifications} icon>
              <Icon
                name={
                  hasUnreadError === true
                    ? 'exclamation-circle'
                    : 'check-circle'
                }
                small
                solid
                border={theme.colors.gray}
                background={theme.colors.background}
                color={
                  hasUnreadError === true
                    ? theme.colors.alert
                    : theme.colors.success
                }
              />
            </LinkColumn>
            <Column icon>
              <SignalIndicator percentage={signalStrengthPct} />
            </Column>
            <Column>{/* For space distribution */}</Column>
            <Column actions>
              <Action horizontal onClick={loadModule}>
                <Icon small name="sync" />
              </Action>
              {canEditFieldModule && (
                <Action horizontal to={`/modules/${id}/edit`}>
                  <Icon small name="edit" />
                </Action>
              )}
              <Action horizontal onClick={onClose}>
                <Icon small name="close" />
              </Action>
            </Column>
          </Row>
        </Body>
      </Table>
      <H2>{name}</H2>

      <TableContainer>
        <Table>
          {hasValves && <Title>{t('models:valve_plural')}</Title>}
          <Body>
            {(valves || []).map(
              (
                {
                  name,
                  currentValue,
                  hasUnreadError,
                  id,
                  pressure,
                  currentIsSuspended,
                },
                index
              ) => {
                return (
                  <Row key={index}>
                    <LinkColumn onClick={navigateToValve(id)} icon>
                      <Icon
                        small
                        bordered={false}
                        rotate={currentIsSuspended ? 90 : undefined}
                        name={currentIsSuspended ? 'manual' : 'sync'}
                      />
                    </LinkColumn>
                    <LinkColumn onClick={navigateToValve(id)} icon>
                      <Icon
                        small
                        bordered={false}
                        name={`circle-${currentValue || 0}`}
                      />
                    </LinkColumn>
                    <LinkColumn onClick={navigateToValve(id)} icon>
                      {!!pressure && (
                        <Icon
                          small
                          bordered={false}
                          name="tint"
                          color={theme.colors.water}
                        />
                      )}
                    </LinkColumn>
                    <LinkColumn onClick={navigateToValve(id)}>
                      {name}
                    </LinkColumn>
                    <Column actions={1}>
                      <Action onClick={navigateToNotifications}>
                        <Icon
                          name={
                            hasUnreadError === true
                              ? 'exclamation-circle'
                              : 'check-circle'
                          }
                          small
                          solid
                          border={theme.colors.gray}
                          background={theme.colors.background}
                          color={
                            hasUnreadError === true
                              ? theme.colors.alert
                              : theme.colors.success
                          }
                        />
                      </Action>
                    </Column>
                  </Row>
                )
              }
            )}
          </Body>
        </Table>
        <Table>
          {hasSensorValues && <Title>{t('models:sensor_plural')}</Title>}
          <Body>
            {(sensorValues || []).map(
              (
                { id, name, unit, currentValue: { value, timestamp } },
                index
              ) => {
                return (
                  <Row key={index}>
                    <Column icon>
                      <Action onClick={() => toggleChart(id)}>
                        <Icon
                          small
                          bordered={false}
                          name="chart-line"
                          color={
                            sensorCharts[id]
                              ? theme.colors.text
                              : theme.colors.gray
                          }
                        />
                      </Action>
                    </Column>
                    <LinkColumn onClick={() => toggleChart(id)}>
                      {name}
                    </LinkColumn>
                    <LinkColumn onClick={() => toggleChart(id)}>
                      <CurrentSensorValueWrapper>
                        {formatValue(value)}{' '}
                        {t(`attributes:sensorValue:unitOptions:${unit}`)}
                      </CurrentSensorValueWrapper>
                    </LinkColumn>
                  </Row>
                )
              }
            )}
          </Body>
        </Table>
      </TableContainer>
      {(sensorValues || []).map(({ id, name }) => {
        if (sensorCharts[id]) {
          return <Chart key={id} id={id} name={name} />
        }
      })}
    </SelectedModule>
  )
}

export default withTheme(ModuleOverlay)
