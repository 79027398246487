import React, { useEffect } from 'react'
import {
  Button,
  Dropdown,
  FormField,
  StyledForm,
} from '../../../components/forms'
import { useDispatch, useSelector } from 'react-redux'
import { temporarySuspensions } from '../../../redux/actions'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { formatDistance, addDays } from 'date-fns'
import { locales } from '../../../i18n'
import { byValveId } from '../../../redux/selectors/temporarySuspensions'
import styled from 'styled-components'

const dropdownOptions = (language) =>
  [1, 2, 3, 4, 5, 6, 7].map((days) => {
    return {
      id: days * 24,
      name: formatDistance(addDays(new Date(), days), new Date(), {
        addSuffix: false,
        locale: locales[language],
      }),
    }
  })

const FormContainer = styled.div`
  max-width: 20em;
  margin-bottom: 2em;
`

const TemporarySuspensionForm = ({ valveId }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (valveId) {
      dispatch(temporarySuspensions.list({ search: `valve_id=${valveId}` }))
    }
  }, [dispatch, valveId])

  const currentSuspension = useSelector(byValveId(valveId))
  const { suspendedUntil } = currentSuspension || {}
  const handleSubmit = ({ hours }) => {
    if (currentSuspension) {
      // if there is already a suspension => abort it by discarding it
      dispatch(temporarySuspensions.destroy(currentSuspension.id))
    } else {
      // there is no suspension yet => create it
      dispatch(
        temporarySuspensions.create({}, { valveId: valveId, hours: hours })
      )
    }
  }

  return (
    <div>
      <p>{t('attributes:valve:temporarySuspension:description')}</p>
      {suspendedUntil && (
        <p>
          {t('attributes:valve:temporarySuspension:suspendedUntil', {
            suspendedUntil,
          })}
        </p>
      )}
      <Form
        initialValues={{
          hours: 24,
        }}
        onSubmit={(attributes) => {
          handleSubmit(attributes)
        }}
      >
        {({ handleSubmit, values }) => (
          <FormContainer>
            <StyledForm
              singleColumn
              id="temporarySuspension"
              onSubmit={handleSubmit}
            >
              {!currentSuspension && (
                <FormField
                  name="hours"
                  label={t('attributes:valve:temporarySuspension:suspendFor')}
                  options={dropdownOptions(i18n.language)}
                  component={Dropdown}
                />
              )}
              <Button smaller type="submit">
                {t(
                  `attributes:valve:temporarySuspension:${
                    currentSuspension ? 'unsuspend' : 'suspend'
                  }`
                )}
              </Button>
            </StyledForm>
          </FormContainer>
        )}
      </Form>
    </div>
  )
}

export default TemporarySuspensionForm
