// https://github.com/redux-saga/redux-saga/issues/14#issuecomment-168997804

import { getLocation, push } from 'connected-react-router'
// import i18n from '../../i18n'
import { decode } from 'jsonwebtoken'

import { call, delay, put, race, select, take } from 'redux-saga/effects'
import { addFlashMessage } from '../actions/flashMessage'
import { FETCH_RESOURCE_SUCCESS } from '../actions'
import { profile as profileResource } from '../actions/resource'
import { ApiError } from '../../lib/api'
import i18n from '../../i18n'

// watches profile and changes language of app accordingly
export default function* changeLanguage() {
  while (true) {
    const action = yield take(FETCH_RESOURCE_SUCCESS)

    // console.log("i18n saga", action)
    if (action?.resource?.name === 'profile') {
      const language = action.payload?.json?.settings?.language

      if (language != undefined && language != i18n.language) {
        console.log(`changing language from ${i18n.language} to ${language}`)
        i18n.changeLanguage(language)
      }
    }
  }
}
