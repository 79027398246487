import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'

import { signIn } from '../../redux/actions/authorization'
import { getSignInError } from '../../redux/reducers'
import FlashMessage from '../../components/FlashMessage'

import { Actions, Background, Error, LoginButton, LoginInput } from './styles'

const Login = ({ login, error, t }) => {
  return (
    <Background>
      <Form onSubmit={({ email, password }) => login(email, password)}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h1>{t('signInPrompt')}</h1>
            <LoginInput
              name="email"
              placeholder={t('email')}
              autoComplete="username"
            />
            <LoginInput
              name="password"
              placeholder={t('password')}
              type="password"
              autoComplete="current-password"
            />
            <Actions>
              <Link to="/login/password/new">{t('forgotPassword')}</Link>
              <LoginButton type="submit">{t('signIn')}</LoginButton>
            </Actions>
          </form>
        )}
      </Form>
    </Background>
  )
}

const mapStateToProps = (state) => ({
  error: getSignInError(state),
})

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(signIn(email, password)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login))
