import React from 'react'

import { Route, Switch } from 'react-router-dom'

import List from './List'
import Show from './Show'
import Edit from './Edit'
import CreateShift from '../shifts/Edit'

export default () => (
  <Switch>
    <Route path="/programs" exact component={List} />
    <Route path="/programs/new" exact component={Edit} />
    <Route path="/programs/:id" exact component={Show} />
    <Route path="/programs/:id/edit" exact component={Edit} />
    <Route
      path="/programs/:programId/shifts/new"
      exact
      component={CreateShift}
    />
  </Switch>
)
