import React from 'react'
import Table, { Body, Column, Row } from '../../../components/Table'
import HideForRoles from '../../../components/auth/HideForRoles'
import CurrentHumidityValue from './components/CurrentHumidity'
import CurrentThreshValue from './components/CurrentThreshValue'
import Action from '../../../components/Action'
import Icon from '../../../components/Icon'
import { useTranslation } from 'react-i18next'

const SensorBasedIrrigationDisabled = ({ editSensorBasedIrrigationPath }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Column>
        <strong>
          {t('attributes:valve:sensorBasedIrrigation.disabledText')}
        </strong>
      </Column>
      <Column actions={1}>
        <Action to={editSensorBasedIrrigationPath}>
          <Icon small name="edit" />
        </Action>
      </Column>
    </Row>
  )
}

const Show = ({ valve, valveGroupId }) => {
  const { t } = useTranslation()
  const { sensorBasedIrrigationEnabled, wateringCoefficient } = valve || {}

  const wateringCoefficientPct = wateringCoefficient * 100

  if (!valve) {
    return null
  }

  const editSensorBasedIrrigationPath = valveGroupId
    ? `/valves/${valve.id}/sensor_based_irrigation?valveGroupId=${valveGroupId}`
    : `/valves/${valve.id}/sensor_based_irrigation`

  if (!sensorBasedIrrigationEnabled) {
    return (
      <SensorBasedIrrigationDisabled
        editSensorBasedIrrigationPath={editSensorBasedIrrigationPath}
        t={t}
      />
    )
  }

  return (
    <Table>
      <Body>
        <Row>
          <Column>
            <span>
              {t(
                'attributes:valve:sensorBasedIrrigation:hints:sensorBasedIrrigationActivated'
              )}
            </span>
          </Column>
          <Column actions={1}>
            <Action to={editSensorBasedIrrigationPath}>
              <Icon small name="edit" />
            </Action>
          </Column>
        </Row>

        <HideForRoles roles={['farmer']}>
          <Row>
            <Column>
              <p>
                {t('attributes:valve:sensorBasedIrrigation:hints:thresholds')}
              </p>
              <p>
                {t(
                  'attributes:valve:sensorBasedIrrigation:hints:wateringCoefficient'
                )}
              </p>
            </Column>
          </Row>
        </HideForRoles>

        <CurrentHumidityValue valve={valve} />
        <CurrentThreshValue valve={valve} />

        <Row>
          <Column>
            <strong>
              {t('attributes:valve:sensorBasedIrrigation.wateringCoefficient')}
            </strong>
            : {`${wateringCoefficientPct}%`}
            <p>
              {t(
                'attributes:valve:sensorBasedIrrigation:hints:nextWateringDuration',
                { wateringCoefficientPct }
              )}
            </p>
          </Column>
        </Row>
      </Body>
    </Table>
  )
}

export default Show
