import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Show from './Show'
import AddValve from './AddValve'
import Edit from './Edit'

export default () => (
  <Switch>
    <Route path="/valve_groups/:id" exact component={Show} />
    <Route path="/valve_groups/:id/add_valve" exact component={AddValve} />
    <Route path="/valve_groups/:id/edit" exact component={Edit} />
  </Switch>
)
