import parseISO from 'date-fns/parseISO'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'
import { ApiError } from '../../lib/api'
import { shifts as shiftsResource } from '../../redux/actions/resource'
import { getShiftById } from '../../redux/reducers'

import Action from '../../components/Action'
import {
  DateTime,
  StyledForm,
  FormToggle,
  FormField,
  Weekdays,
  Dropdown,
} from '../../components/forms'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'
import ValveGroupList from './ValveGroupList'

const EditShift = ({ history, match }) => {
  const { t } = useTranslation()
  const shiftId = match.params.id
  const programId = match.params.programId
  const shift =
    shiftId == null ? {} : useSelector((state) => getShiftById(state, shiftId))
  const dispatch = useDispatch()

  useEffect(() => {
    if (shiftId != null) {
      dispatch(shiftsResource.read(shiftId))
    }
  }, [dispatch, shiftId])

  const { id, name, startsAt, endsAt, interval, sensorBasedIrrigationEnabled } =
    shift || {}
  const { intervalType, period, weekdays } = interval || {}
  const repeatOptions = t(`attributes:shift.repeatOptions`)

  console.log('startsAt', startsAt)

  const updateShift = (id, attributes) =>
    dispatch(shiftsResource.update(id, undefined, attributes))

  const createShift = (attributes) =>
    dispatch(shiftsResource.create({ programId }, attributes))

  return (
    <>
      <Toolbar title={name}>
        <Action vertical form="editShift">
          <Icon name="confirm" />
        </Action>
      </Toolbar>

      <Form
        initialValues={{
          name,
          startsAt,
          endsAt,
          sensorBasedIrrigationEnabled,
          intervalAttributes: {
            intervalType,
            period,
            weekdays,
          },
        }}
        onSubmit={(attributes) => {
          const isCreate = id == null
          const result = isCreate
            ? createShift(attributes)
            : updateShift(id, attributes)

          console.log('promise', result)

          return result.promise.then((result) => {
            if (result instanceof ApiError) {
              const { errors } = result.json
              const ret = Object.keys(errors).reduce((ret, key) => {
                ret[key] = errors[key].join(', ')
                return ret
              }, {})
              return ret
            } else {
              isCreate
                ? history.push(`/programs/${programId}`)
                : history.push(`/shifts/${id}`)
            }
          })
        }}
      >
        {({ handleSubmit, values }) => (
          <StyledForm id="editShift" divided onSubmit={handleSubmit}>
            <FormField
              name="name"
              label={t('attributes:shift.name')}
              required
            />
            <FormField name="intervalAttributes[id]" type="hidden" />
            <FormField
              name="startsAt"
              label={t('attributes:shift.startsAt')}
              component={DateTime}
              required
            />
            <FormField
              name="endsAt"
              label={t('attributes:shift.endsAt')}
              component={DateTime}
              minDate={parseISO(values.startsAt)}
              required
            />
            <FormField
              name="sensorBasedIrrigationEnabled"
              noLine
              label={t('attributes:shift.sensorBasedIrrigationEnabled')}
              component={FormToggle}
            />
            <FormField
              name="intervalAttributes.intervalType"
              label={t('attributes:shift.interval')}
              options={Object.keys(repeatOptions).map((id) => ({
                id,
                name: repeatOptions[id],
              }))}
              component={Dropdown}
            />

            {values.intervalAttributes.intervalType !== 'on_days' && (
              <FormField
                name="intervalAttributes.period"
                label={t('attributes:shift.period')}
                type="number"
                required={values.intervalAttributes.intervalType !== 'on_days'}
              />
            )}

            {values.intervalAttributes.intervalType === 'on_days' && (
              <FormField
                name="intervalAttributes.weekdays"
                label={t('attributes:shift.weekdays')}
                component={Weekdays}
              />
            )}
          </StyledForm>
        )}
      </Form>
    </>
  )
}

export default withRouter(EditShift)
