import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'
import { ApiError } from '../../../lib/api'
import {
  sensorValues as sensorValuesResource,
  valves as valvesResource,
} from '../../../redux/actions/resource'
import {
  getAllSensorValues,
  getCurrentProjectId,
  getPendingResourceCount,
  getValveById,
} from '../../../redux/reducers'
import Action from '../../../components/Action'
import {
  Dropdown,
  FormField,
  FormToggle,
  StyledForm,
  ValidationError,
} from '../../../components/forms'
import Icon from '../../../components/Icon'
import Toolbar from '../../../components/Toolbar'
import { confirm } from '../../../components/Alert'
import {
  MULTI_THRESHOLD_IRRIGATION_TYPE,
  SENSOR_THRESHOLD_IRRIGATION_TYPE,
  SINGLE_THRESHOLD_IRRIGATION_TYPE,
} from './constants'
import { SingleThresholdIrrigationFields } from './SingleThresholdIrrigationFields'
import { MultiThresholdIrrigationFields } from './MultiThresholdIrrigationFields'
import SensorThresholdIrrigationFields from './SensorThresholdIrrigationFields'
import HideForRoles from '../../../components/auth/HideForRoles'
import CurrentHumidityValue from './components/CurrentHumidity'
import CurrentThreshSensorValue from './components/CurrentThreshSensorValue'

const EditSensorBasedIrrigation = ({ history, match }) => {
  const { t } = useTranslation()
  const valveId = match.params.id
  const useQuery = () => new URLSearchParams(useLocation().search)
  const valveGroupId = useQuery().get('valveGroupId')
  const valve =
    valveId == null ? {} : useSelector((state) => getValveById(state, valveId))
  const dispatch = useDispatch()
  const projectId = useSelector(getCurrentProjectId)
  const pendingResources = useSelector(getPendingResourceCount)
  const [fetchDispatched, setFetchDispatched] = useState(false)
  const [loadingCompleted, setLoadingCompleted] = useState(false)
  const [initialValues, setInitialValues] = useState({
    sensorBasedIrrigationEnabled: false,
    sensorBasedIrrigationAttributes: {
      sensorValueId: 0,
      thresh0: '',
      thresh100: '',
      type: SINGLE_THRESHOLD_IRRIGATION_TYPE,
    },
  })

  // get resources from API (but only on first run when dispatch is created)
  useEffect(() => {
    if (valveId != null) {
      console.log('dispatching requests for dependent resources')
      dispatch(valvesResource.read(valveId))
      dispatch(sensorValuesResource.list({ projectId }))
      setFetchDispatched(true)
    }
  }, [dispatch])

  // update loadingCompleted state dependent on pending resources
  useEffect(() => {
    // only set loadingCompleted to true if
    // - the requests have been dispatched
    // - loadingCompleted is not already true
    // - and there are no pending resources left
    if (!loadingCompleted && pendingResources == 0 && fetchDispatched) {
      console.log('set loading completed!')
      setLoadingCompleted(true)
    } else if (loadingCompleted) {
      console.log('loading completed')
    } else {
      console.log('loading NOT completed!')
    }
  }, [loadingCompleted, pendingResources, fetchDispatched])

  // set initial form values when loading all resources is completed
  // this is necessary to avoid changes in form state when valve has been updated via ActionCable
  useEffect(() => {
    if (loadingCompleted) {
      const { name, sensorBasedIrrigation, sensorBasedIrrigationEnabled } =
        valve || {}
      const { type, thresh0, thresh100, sensorValueId, threshSensorValueId } =
        sensorBasedIrrigation || {}
      setInitialValues({
        sensorBasedIrrigationEnabled,
        sensorBasedIrrigationAttributes: {
          sensorValueId: sensorValueId ? sensorValueId : 0,
          threshSensorValueId: threshSensorValueId ? threshSensorValueId : null,
          thresh0: thresh0 ? thresh0 : '',
          thresh100: thresh100 ? thresh100 : '',
          type: type ? type : SINGLE_THRESHOLD_IRRIGATION_TYPE,
        },
      })
    }
  }, [loadingCompleted])

  const updateValve = (id, attributes) => {
    return dispatch(valvesResource.update(id, undefined, attributes))
  }

  const sensorValues = useSelector((state) => getAllSensorValues(state))

  const sensorOptions =
    sensorValues !== undefined
      ? sensorValues
      : [
          {
            id: null,
            name: 'Kein Sensor gefunden',
            unit: '',
            availableForSensorBasedIrrigation: true,
            availableForSensorThresholdIrrigation: true,
          },
        ]

  const addCurrentValueAndTimestampToSensorValueName = (sensorValue) => {
    // return unchanged sensor value if there is no current value
    if (sensorValue.currentValue.value == null) {
      return sensorValue
    }

    const timestamp = t(`attributes:valve:sensorBasedIrrigation:timestamp`, {
      timestamp: sensorValue.currentValue?.timestamp,
    })
    const name = `${sensorValue.name} | ${
      sensorValue.currentValue?.value || '-'
    }hPa | ${timestamp}`
    return { ...sensorValue, name }
  }

  const sensorOptionsHumidity = sensorOptions
    .filter((x) => x.availableForSensorBasedIrrigation)
    .map(addCurrentValueAndTimestampToSensorValueName)
  const sensorOptionsThreshold = sensorOptions
    .filter((x) => x.availableForSensorThresholdIrrigation)
    .map(addCurrentValueAndTimestampToSensorValueName)

  // only show form when loading is completed
  if (!loadingCompleted) {
    return (
      <div>
        <p>{t('common:pleaseWait')}</p>
      </div>
    )
  }

  return (
    <>
      <Toolbar title={name}>
        <Action vertical form="editSensorBasedIrrigation">
          <Icon name="confirm" />
        </Action>
      </Toolbar>

      <Form
        initialValues={initialValues}
        validate={(values) => {
          const errors = {
            sensorBasedIrrigationAttributes: {},
          }
          if (!values.sensorBasedIrrigationAttributes.type) {
            errors.sensorBasedIrrigationAttributes.type = t(
              'validationErrors:required'
            )
          }
          if (!values.sensorBasedIrrigationAttributes.sensorValueId) {
            errors.sensorBasedIrrigationAttributes.sensorValueId = t(
              'validationErrors:required'
            )
          }
          // thresh0 is required for Single- and MultiThresholdIrrigation, but not for SensorBasedIrrigation
          if (
            values.sensorBasedIrrigationAttributes.type !==
            SENSOR_THRESHOLD_IRRIGATION_TYPE
          ) {
            if (!values.sensorBasedIrrigationAttributes.thresh0) {
              errors.sensorBasedIrrigationAttributes.thresh0 = t(
                'validationErrors:required'
              )
            }
          }
          // thresh100 is only necessary if MULTI_THRESHOLD_IRRIGATION_TYPE is selected
          if (
            values.sensorBasedIrrigationAttributes.type ===
            MULTI_THRESHOLD_IRRIGATION_TYPE
          ) {
            if (!values.sensorBasedIrrigationAttributes.thresh100) {
              errors.sensorBasedIrrigationAttributes.thresh100 = t(
                'validationErrors:required'
              )
            }
          }
          // threshSensorValueId is only necessary if SENSOR_THRESHOLD_IRRIGATION_TYPE is selected
          if (
            values.sensorBasedIrrigationAttributes.type ===
            SENSOR_THRESHOLD_IRRIGATION_TYPE
          ) {
            if (!values.sensorBasedIrrigationAttributes.threshSensorValueId) {
              errors.sensorBasedIrrigationAttributes.threshSensorValueId = t(
                'validationErrors:required'
              )
            }
          }

          return errors
        }}
        onSubmit={(attributes) => {
          const handleResult = (result) => {
            if (result instanceof ApiError) {
              // there was an error => show the errors
              const { errors } = result.json
              const ret = Object.keys(errors).reduce((ret, key) => {
                ret[key] = errors[key].join(', ')
                return ret
              }, {})
              return ret
            } else {
              // there was no error => navigate back to valve
              history.goBack()
            }
          }

          // if there is a valve group selected => ask if settings should be applied to all valves of this valve group
          if (valveGroupId) {
            console.log('update all valves of valve group')
            return confirm(
              'Sollen die Einstellungen auf alle Ventile der Ventilgruppe angewendet werden?',
              null
            )
              .then((applyToValveGroup) => {
                return updateValve(valveId, {
                  ...attributes,
                  batchParams: {
                    sensorBasedIrrigation: {
                      applyToValveGroup: applyToValveGroup
                        ? valveGroupId
                        : null,
                    },
                  },
                }).promise
              })
              .then(handleResult)
          } else {
            console.log('update single valve')
            // dont ask and update only the current valve
            return updateValve(valveId, attributes).promise.then(handleResult)
          }
        }}
      >
        {({ handleSubmit, values, errors, touched, ...rest }) => {
          const selectedSensorValue = sensorOptions.filter(
            (x) => x.id === values.sensorBasedIrrigationAttributes.sensorValueId
          )[0]

          const { unit } = selectedSensorValue || { unit: '' }

          // TODO: refactor these thre variables
          const singleThresholdSelected =
            values.sensorBasedIrrigationAttributes.type ===
            SINGLE_THRESHOLD_IRRIGATION_TYPE
          const multiThresholdSelected =
            values.sensorBasedIrrigationAttributes.type ===
            MULTI_THRESHOLD_IRRIGATION_TYPE
          const sensorThresholdSelected =
            values.sensorBasedIrrigationAttributes.type ===
            SENSOR_THRESHOLD_IRRIGATION_TYPE

          return (
            <StyledForm
              id="editSensorBasedIrrigation"
              singleColumn
              onSubmit={handleSubmit}
            >
              <FormField
                name="sensorBasedIrrigationEnabled"
                noLine
                label={t('attributes:shift:sensorBasedIrrigationEnabled')}
                component={FormToggle}
              />
              {/*<CurrentHumidityValue valve={valve} />*/}
              {/*<CurrentThreshSensorValue valve={valve} />*/}
              <HideForRoles roles={['farmer']}>
                <FormField
                  name="sensorBasedIrrigationAttributes[sensorValueId]"
                  label={t('attributes:valve:sensorBasedIrrigation.sensor')}
                  options={sensorOptionsHumidity}
                  component={Dropdown}
                />
              </HideForRoles>
              {errors.sensorBasedIrrigationAttributes &&
                errors.sensorBasedIrrigationAttributes.sensorValueId &&
                touched['sensorBasedIrrigationAttributes[sensorValueId]'] && (
                  <div styles="column-span: all;">
                    <ValidationError>
                      {errors.sensorBasedIrrigationAttributes.sensorValueId}
                    </ValidationError>
                  </div>
                )}
              <FormField
                name="sensorBasedIrrigationAttributes[type]"
                label={t('attributes:valve:sensorBasedIrrigation.type')}
                options={t(
                  'attributes:valve:sensorBasedIrrigation.typeOptions'
                )}
                component={Dropdown}
              />
              {errors.sensorBasedIrrigationAttributes &&
                errors.sensorBasedIrrigationAttributes.type &&
                touched['sensorBasedIrrigationAttributes[type]'] && (
                  <div styles="column-span: all;">
                    <ValidationError>
                      {errors.sensorBasedIrrigationAttributes.type}
                    </ValidationError>
                  </div>
                )}
              {singleThresholdSelected ? (
                <SingleThresholdIrrigationFields
                  errors={errors}
                  touched={touched}
                  t={t}
                  sensorUnit={unit}
                />
              ) : null}
              {multiThresholdSelected ? (
                <MultiThresholdIrrigationFields
                  errors={errors}
                  touched={touched}
                  t={t}
                  sensorUnit={unit}
                />
              ) : null}
              {sensorThresholdSelected ? (
                <SensorThresholdIrrigationFields
                  errors={errors}
                  touched={touched}
                  t={t}
                  sensorUnit={unit}
                  sensorOptions={sensorOptionsThreshold}
                />
              ) : null}
            </StyledForm>
          )
        }}
      </Form>
    </>
  )
}

export default withRouter(EditSensorBasedIrrigation)
