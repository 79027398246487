import React from 'react'
import { useSelector } from 'react-redux'
import { getSensorValueById } from '../../../../redux/reducers'
import { Column, Row } from '../../../../components/Table'
import { useTranslation } from 'react-i18next'

function CurrentThreshSensorValue({ valve }) {
  const { t } = useTranslation()
  if (valve === null || valve === undefined) {
    return <></>
  }
  const { sensorBasedIrrigation } = valve || {}
  const { threshSensorValueId } = sensorBasedIrrigation || {}
  const sensorValue = useSelector((state) =>
    getSensorValueById(state, threshSensorValueId)
  )
  const { currentValue } = sensorValue || {}
  const { value, timestamp } = currentValue || {}

  return (
    <Row>
      <Column>
        <span style={{ fontWeight: 'bold', paddingRight: '1em' }}>
          {t('attributes:valve:sensorBasedIrrigation:recommendedThreshold')}
        </span>
        <span style={{ marginRight: '1em' }}>{value ? value : ' - '}hPa</span>
        <span style={{ color: 'lightgray' }}>
          (
          {t(`attributes:valve:sensorBasedIrrigation:timestamp`, {
            timestamp,
          })}
          )
        </span>
      </Column>
    </Row>
  )
}

export default CurrentThreshSensorValue
