import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'

import styled, { withTheme } from 'styled-components'

import {
  notifications as notificationsResource,
  projects as projectsResource,
} from '../../redux/actions/resource'
import { getNotificationById, getProjectById } from '../../redux/reducers'

import { setCurrentProject } from '../../redux/actions/currentProject'

import Action from '../../components/Action'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'

const Container = styled.div`
  margin-left: 45px;
  margin-right: 45px;
`

const Row = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 15px;
  align-items: center;
`

const Severity = withTheme(({ severity, theme }) =>
  severity == 'error' ? (
    <InfoContainer>
      <Icon name="exclamation-circle" color={theme.colors.alert} />
    </InfoContainer>
  ) : severity == 'warning' ? (
    <InfoContainer>
      <Icon name="info-circle" color={theme.colors.warn} />
    </InfoContainer>
  ) : (
    <InfoContainer>
      <Icon name="info-circle" color={theme.colors.success} />
    </InfoContainer>
  )
)

const InfoContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`

const Tag = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
`

const TagLink = styled.div`
  background: ${(props) => props.theme.colors.lightGray};
  border-radius: 16px;
  margin-right: 10px;
  padding-right: 6px;
  display: inline-block;
  align-items: center;
`

const TagLinkText = styled.p`
  width: auto;
  height: 32px;
  margin-left: 14px;
  margin-right: 4.5px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text};
  line-height: 32px;
  text-align: center;
`

const NotificationTitle = styled.div`
  font-weight: bold;
`

const NotificationDescription = styled.div``

const NotificationDate = styled.div``

const NotificationTime = styled.div`
  font-weight: bold;
`

const lastPart = (string) => {
  const parts = string.toString().split(':')
  return parts[parts.length - 1]
}

const Show = ({ t }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const notificationId = match.params.id
  const notification = useSelector((state) =>
    getNotificationById(state, notificationId)
  )
  const [readAt, setReadAt] = useState('1')
  const dispatch = useDispatch()
  const project = useSelector((state) =>
    getProjectById(state, notification.projectId)
  )

  useEffect(() => {
    dispatch(notificationsResource.read(notificationId))
  }, [dispatch, readAt])

  const updateReadAt = (i18n) => {
    setReadAt(lastPart(i18n))
  }

  const setProjectAndNavigate = (to) => (e) => {
    dispatch(setCurrentProject(notification.projectId))
    history.push(to)
    e.stopPropagation()
  }
  console.log(notification)
  return (
    <>
      <Toolbar
        title={
          notification.severity == 'error'
            ? t('attributes:notification:severityOptions.2')
            : notification.severity == 'warning'
            ? t('attributes:notification:severityOptions.1')
            : t('attributes:notification:severityOptions.0')
        }
        onBack={() => history.push('/notifications')}
      >
        <Action onClick={updateReadAt}>
          <Icon name="check" />
        </Action>
      </Toolbar>
      <Container>
        <Row>
          <Tag>
            {notification.actionUrl && (
              <Action onClick={setProjectAndNavigate(notification.actionUrl)}>
                <TagLink>
                  <TagLinkText>
                    {notification.actionName || 'Zum Fehler'}
                  </TagLinkText>
                </TagLink>
              </Action>
            )}
          </Tag>
          <Tag>
            {notification.projectId && (
              <Action to={`/projects/${notification.projectId}`}>
                <TagLink>
                  <TagLinkText>{project.name || 'Zum Projekt'}</TagLinkText>
                </TagLink>
              </Action>
            )}
          </Tag>
        </Row>
        <Row>
          <Severity severity={notification.severity} />
          <NotificationTitle>{notification.title}</NotificationTitle>
        </Row>
        <Row>
          <NotificationDescription>
            {notification.description}
          </NotificationDescription>
        </Row>
        <Row>
          <NotificationDate>
            {moment(notification.createdAt).format('DD.MM.YYYY')}
          </NotificationDate>
          <NotificationTime>
            {moment(notification.createdAt).format('HH:mm:ss')}
          </NotificationTime>
        </Row>
      </Container>
    </>
  )
}

export default withTranslation()(withTheme(Show))
