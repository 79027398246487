// https://github.com/redux-saga/redux-saga/issues/14#issuecomment-168997804

import { getLocation, push } from 'connected-react-router'
// import i18n from '../../i18n'
import { decode } from 'jsonwebtoken'

import { call, delay, put, race, select, take } from 'redux-saga/effects'
import { addFlashMessage } from '../actions/flashMessage'
import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_IN_ERROR,
  signInSuccess,
  signInError,
  signOutSuccess,
} from '../actions'
import { profile as profileResource } from '../actions/resource'
import { ApiError } from '../../lib/api'
import i18n from '../../i18n'

function* fetchProfile() {
  const request = yield put(profileResource.read())
  const profile = yield request.promise

  if (!(profile instanceof ApiError)) {
    i18n.changeLanguage(profile?.settings?.language)
  }
}
// Executes the actual authorize request
//
// "auth" are all exports from lib/auth (dependency injection for better testing)
function* authorize(tokenOrCredentials, auth) {
  try {
    console.log('authorize')
    const token = yield call(auth.authorize, tokenOrCredentials)
    yield call(auth.storeToken, token)
    yield call(fetchProfile)
    yield put(signInSuccess(token))
    const location = yield select(getLocation)
    // redirect to dashboard if signed in successfully
    if (location.pathname === '/login') {
      yield put(push('/dashboard'))
    }
    return token
  } catch (e) {
    // failed to sign in
    // => reset token
    yield call(auth.storeToken, null)
    // show error
    yield put(
      addFlashMessage('error', i18n.t('errors:login:wrongUsernameOrPassword'))
    )
    yield put(signInError(e))
    return null
  }
}

// "auth" are all exports from lib/auth (dependency injection for better testing)
function* authorizeLoop(token, auth) {
  while (true) {
    console.log('authorizeLoop')
    // try to get the token from api
    token = yield call(authorize, token, auth)

    // return immediately if token is null
    if (token == null) {
      return
    }

    // wait for token expiry if token is present
    const expiresIn = auth.expiresIn(token)
    console.log(`Expires in ${expiresIn / 1000} seconds`)
    yield delay(expiresIn)
  }
}

// "auth" are all exports from lib/auth (dependency injection for better testing)
export default function* authentication(auth) {
  let credentials = null
  while (true) {
    const storedToken = yield call(auth.getStoredToken)
    console.log('authentication', !!storedToken, !!credentials)

    // if there is no token AND no credentials
    // TODO: credentials is always null, it is set to null always
    if (!(storedToken || credentials)) {
      console.log('login required (there is no stored token or credentials yet')
      // dispatch sign out
      yield put(signOutSuccess())
      // wait for new credentials
      credentials = yield take(SIGN_IN)
    }

    // wait for SIGN_OUT, SIGN_IN_ERROR or authLoop
    // the result will be the one that was the fastest
    const result = yield race({
      signOutAction: take(SIGN_OUT),
      signInError: take(SIGN_IN_ERROR),
      authLoop: call(authorizeLoop, storedToken || credentials, auth),
    })
    credentials = null

    if (result.signOutAction) {
      // if SIGN_OUT "has won the previous race" (because user clicked the sign out button)
      console.log('authentication - signOutAction')
      // reset the token
      yield call(auth.storeToken, null)
      // signed out successfully
      yield put(signOutSuccess())
    }
  }
}
