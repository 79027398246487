import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { H2 } from '../../components/type'

import { modules as modulesResource } from '../../redux/actions/resource'

import { getAllModules, getCurrentProject } from '../../redux/reducers'

import { confirm } from '../../components/Alert'
import ListToolbar from '../../components/ListToolbar'
import Toolbar from '../../components/Toolbar'
import ModuleListPart from './ModuleListPart'

const byType = (moduleType) => ({ type }) => type === moduleType

const List = ({ t, history }) => {
  const fieldMdules = useSelector((state) =>
    getAllModules(state, byType('FieldModule'))
  )
  const centralMdules = useSelector((state) =>
    getAllModules(state, byType('CentralModule'))
  )
  const currentProject = useSelector(getCurrentProject)
  const projectId = currentProject?.id
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(modulesResource.list({ projectId }))
  }, [dispatch])

  const onSearch = (search) => {
    if (currentProject) {
      dispatch(modulesResource.list({ projectId, search }))
    }
  }

  const onDeleteModule = async (id) => {
    if (await confirm('Möchtest du dieses Modul wirklich löschen?')) {
      dispatch(modulesResource.destroy(id))
    }
  }

  return (
    <>
      <Toolbar
        title={t('models:module', { count: 0 })}
        onBack={() => history.push('/dashboard')}
      ></Toolbar>
      <ListToolbar
        useBarcode={false}
        onSearch={onSearch}
        searchColumns={['name', 'serial_number']}
      >
        {({ showTiles }) => {
          return (
            <>
              <ModuleListPart
                header={<H2>{t(`models:central_module`, { count: 0 })}</H2>}
                values={centralMdules}
                showTiles={showTiles}
                onDelete={onDeleteModule}
                itemType="CentralModule"
              />
              <br />
              <ModuleListPart
                values={fieldMdules}
                header={<H2>{t(`models:fieldModule`, { count: 0 })}</H2>}
                showTiles={showTiles}
                onDelete={onDeleteModule}
                itemType="FieldModule"
              />
            </>
          )
        }}
      </ListToolbar>
    </>
  )
}

export default withRouter(withTranslation()(List))
