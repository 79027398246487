import { Dropdown, FormField, ValidationError } from '../../../components/forms'
import { thresh0Label } from './utils'
import React from 'react'
import HideForRoles from '../../../components/auth/HideForRoles'

export const SensorThresholdIrrigationFields = ({
  errors,
  touched,
  t,
  sensorUnit,
  sensorOptions,
}) => {
  return (
    <>
      <HideForRoles roles={['farmer']}>
        <FormField
          name="sensorBasedIrrigationAttributes[threshSensorValueId]"
          label={t('attributes:valve:sensorBasedIrrigation.threshold_sensor')}
          options={sensorOptions.filter(
            (x) => x.availableForSensorThresholdIrrigation
          )}
          component={Dropdown}
        />
      </HideForRoles>
      {errors.sensorBasedIrrigationAttributes &&
        errors.sensorBasedIrrigationAttributes.threshSensorValueId &&
        touched['sensorBasedIrrigationAttributes[threshSensorValueId]'] && (
          <div styles="column-span: all;">
            <ValidationError>
              {errors.sensorBasedIrrigationAttributes.threshSensorValueId}
            </ValidationError>
          </div>
        )}
    </>
  )
}

export default SensorThresholdIrrigationFields
