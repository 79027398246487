import React from 'react'
import { renderToString } from 'react-dom/server'
import { Marker } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAllSensorValues } from '../../redux/reducers'
import wordwrap from 'wordwrapjs'

const SHOW_NAME_ZOOM_LEVEL_GT = 16
const NAME_CHARS_PER_LINE = 19

const FieldModuleLabel = ({ show, line1, line2 }) => {
  return show ? (
    <text
      x="75"
      y="16"
      fill="white"
      fontSize="16px"
      fontFamily="'Asap',sans-serif"
    >
      {line1 && (
        <tspan x="50%" textAnchor="middle">
          {line1}
        </tspan>
      )}
      {line2 && (
        <tspan x="50%" textAnchor="middle" dy="15">
          {line2}
        </tspan>
      )}
    </text>
  ) : null
}

const SensorValue = ({ sensorValue, index }) => {
  const { t } = useTranslation()
  const { currentValue, name, unit } = sensorValue
  const { value, timestamp } = currentValue
  const unitTranslated = t(`attributes:sensorValue:unitOptions:${unit}`)
  // first element has no dy offset
  const dy = index == 0 ? '0' : '15'

  return (
    sensorValue && (
      <tspan x="0" textAnchor="start" dy={dy}>
        {name} <tspan className="value">{value || '-'}</tspan>{' '}
        <tspan className="unit">{unitTranslated}</tspan>
      </tspan>
    )
  )
}

const SensorValues = ({ sensorValues, offsetY }) => {
  const y = (60 + Number(offsetY)).toString()
  return (
    <>
      <style type="text/css">{`
        .value{fill: white;font-weight: bold;}
        .unit{fill: white;font-weight: bold;}
      `}</style>
      <text
        x="25"
        y={y}
        fill="white"
        fontSize="14px"
        fontFamily="'Asap',sans-serif"
      >
        {(sensorValues || []).map((sensorValue, index) => (
          <SensorValue sensorValue={sensorValue} index={index} key={index} />
        ))}
      </text>
    </>
  )
}

const Icon = ({
  hasOpenValve,
  hasUnreadError,
  hasSuspendedValve,
  selected,
  name,
  showFieldModuleLabel,
  showSensorValues,
  sensorValues,
}) => {
  const wrappedName = wordwrap.lines(name, { width: NAME_CHARS_PER_LINE })
  const [nameLine1, nameLine2, ..._unusedRest] = wrappedName

  // we need more space for label if it has two lines
  const markerOffsetY = nameLine2 ? '40' : '20'

  const width = showFieldModuleLabel ? '150' : '30'
  const height = showFieldModuleLabel ? '150' : '37'
  const viewBox = `0 0 ${width} ${height}`
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="green"
      xmlns="http://www.w3.org/2000/svg"
    >
      <FieldModuleLabel
        show={showFieldModuleLabel}
        line1={nameLine1}
        line2={nameLine2}
      />

      {showSensorValues && (
        <SensorValues sensorValues={sensorValues} offsetY={markerOffsetY} />
      )}

      <svg
        width="30"
        height="37"
        x={showFieldModuleLabel ? '60' : '0'}
        y={showFieldModuleLabel ? markerOffsetY : '0'}
        viewBox="0 0 30 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 36C15 36 3.5493 23.336 1.81137 19.2455C1.28457 17.8091 1 16.2601 1 14.6465C1 7.11332 7.24368 1 14.9441 1H15.0525C22.7563 1 29 7.11332 29 14.6465C29 16.2601 28.7154 17.8091 28.1886 19.2472C26.4507 23.336 15 36 15 36Z"
          fill={hasOpenValve ? '#1647F3' : 'white'}
          fillOpacity={selected ? 0.9 : 0.35}
        />
        <path
          d="M5.5 5L24.5 24"
          stroke={hasSuspendedValve ? 'white' : 'none'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5 5L6.26001 24"
          stroke={hasSuspendedValve ? 'white' : 'none'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 36C15 36 3.5493 23.336 1.81137 19.2455C1.28457 17.8091 1 16.2601 1 14.6465C1 7.11332 7.24368 1 14.9441 1H15.0525C22.7563 1 29 7.11332 29 14.6465C29 16.2601 28.7154 17.8091 28.1886 19.2472C26.4507 23.336 15 36 15 36Z"
          stroke={hasUnreadError ? '#FF0000' : 'white'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </svg>
  )
}

const FieldModule = ({ module, selected, currentZoom, ...props }) => {
  const filterByModule = ({ wateringModuleId, showOnMap }) =>
    wateringModuleId === module.id && showOnMap
  const sensorValues = useSelector((state) =>
    getAllSensorValues(state, filterByModule)
  )
  const { lat, lng } = module
  const showFieldModuleLabel = currentZoom
    ? currentZoom > SHOW_NAME_ZOOM_LEVEL_GT
    : false
  const icon = renderToString(
    <Icon
      {...module}
      selected={selected}
      showFieldModuleLabel={showFieldModuleLabel}
      showSensorValues={showFieldModuleLabel}
      sensorValues={sensorValues}
    />
  )
  return (
    <Marker
      {...props}
      icon={`data:image/svg+xml;charset=UTF-8,${window.encodeURIComponent(
        icon
      )}`}
      position={{ lat, lng }}
    />
  )
}

export default FieldModule
