import React, { useState } from 'react'
import { getStoredToken } from '../../../lib/auth'
import { useFetch } from '../../../hooks'
import TagListItem from '../../../components/TagListItem'
import UserSearch from '../../../components/users/UserSearch'
import styled from 'styled-components'
import { Button } from '../../../components/forms'
import { withTranslation } from 'react-i18next'
import { url } from '../../../lib/api'

const ValveOwnerList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TagWrapper = styled.div`
  padding: 0.5em;
  margin-top: 2em;
`

const ValveOwnersForm = ({ valve, t }) => {
  const token = getStoredToken()
  const apiHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  const [collectionChanged, setCollectionChanged] = useState(0)
  const [editMode, setEditMode] = useState(false)
  const response = useFetch({
    url: `${url}/valves/${valve.id}/owners`,
    options: {
      method: 'GET',
      headers: apiHeaders,
    },
    refetch: collectionChanged,
  })

  const { projectId } = valve

  if (response && response.error)
    return <p>Ventilbesitzer konnten nicht geladen werden.</p>
  if (!response) return <p>Loading...</p>

  const handleRemoveOwner = async (id) => {
    try {
      const res = await fetch(
        `${url}/valves/${valve.id}/owners?user_id=${id}`,
        {
          method: 'DELETE',
          headers: apiHeaders,
        }
      )
      const json = await res.json()
      if (res.status >= 400) {
        console.log(json)
      } else {
        // update collectionChanged counter to trigger reloading the whole collection
        setCollectionChanged(collectionChanged + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddOwner = async (id) => {
    try {
      const res = await fetch(
        `${url}/valves/${valve.id}/owners?user_id=${id}`,
        {
          method: 'POST',
          headers: apiHeaders,
          body: JSON.stringify({ user_id: id }),
        }
      )
      const json = await res.json()
      if (res.status >= 400) {
        console.log(json)
      } else {
        // update collectionChanged counter to trigger reloading the whole collection
        setCollectionChanged(collectionChanged + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {editMode ? (
        <>
          <p>{t('attributes:valve:owners:description')}</p>
          <span style={{ fontWeight: 'bold' }}>
            {t('attributes:valve:owners:searchLabel')}
          </span>
          <UserSearch
            token={token}
            projectId={projectId}
            onResultClick={(id) => handleAddOwner(id)}
          />
        </>
      ) : (
        <Button autoSize onClick={() => setEditMode(true)}>
          {t('attributes:valve:owners:addUser')}
        </Button>
      )}

      <ValveOwnerList>
        {response &&
          response.data &&
          response.data.map((user) => (
            <TagWrapper>
              <TagListItem
                text={`${user.firstName} ${user.lastName}`}
                onDelete={() => handleRemoveOwner(user.id)}
                deleteable
              />
            </TagWrapper>
          ))}
      </ValveOwnerList>
    </div>
  )
}

export default withTranslation()(ValveOwnersForm)
