export const thresh0Label = (sensorUnit, t) => {
  if (sensorUnit) {
    return t(`attributes:valve:sensorBasedIrrigation:labelWithUnit`, {
      label: t('attributes:valve:sensorBasedIrrigation.thresh0'),
      unit: t(`attributes:sensorValue:unitOptions:${sensorUnit}`),
    })
  } else {
    return t('attributes:valve:sensorBasedIrrigation.thresh0')
  }
}

export const thresh100Label = (sensorUnit, t) => {
  if (sensorUnit) {
    return t(`attributes:valve:sensorBasedIrrigation:labelWithUnit`, {
      label: t('attributes:valve:sensorBasedIrrigation.thresh100'),
      unit: t(`attributes:sensorValue:unitOptions:${sensorUnit}`),
    })
  } else {
    return t('attributes:valve:sensorBasedIrrigation.thresh100')
  }
}
