import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { Link, useParams } from 'react-router-dom'

import { password as passwordResource } from '../../redux/actions/resource'
import { getSignInError } from '../../redux/reducers'

import { Actions, Background, Error, LoginButton, LoginInput } from './styles'

const ChangePassword = ({ changePassword, error, history, t }) => {
  const { resetPasswordToken } = useParams()

  const onSubmit = ({ password, passwordConfirmation }) => {
    return changePassword(
      resetPasswordToken,
      password,
      passwordConfirmation
    ).promise.then(() => {
      history.push('/login')
    })
  }

  return (
    <Background>
      {error ? <Error>Error signing in: {error}</Error> : null}
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h2>{t('resetPassword')}</h2>
            <LoginInput
              name="password"
              placeholder={t('password')}
              type="password"
              autoComplete="new-password"
            />
            <LoginInput
              name="passwordConfirmation"
              placeholder={t('passwordConfirmation')}
              type="password"
              autoComplete="new-password"
            />
            <Actions>
              <Link to="/login">{t('signIn')}</Link>
              <LoginButton type="submit">{t('resetPassword')}</LoginButton>
            </Actions>
          </form>
        )}
      </Form>
    </Background>
  )
}

const mapStateToProps = (state) => ({
  error: getSignInError(state),
})

const mapDispatchToProps = (dispatch) => ({
  changePassword: (resetPasswordToken, password, passwordConfirmation) =>
    dispatch(
      passwordResource.update(resetPasswordToken, null, {
        password,
        passwordConfirmation,
      })
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChangePassword))
