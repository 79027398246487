import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import Tile, { Grid, GridItem } from '../../components/Tile'
import Action from '../../components/Action'
import Icon from '../../components/Icon'
import { TextLink } from '../../components/type'

export const ConnectionIndicator = ({ connected }) => {
  const color = connected ? 'green' : 'red'

  return <span style={{ fontSize: '1.6em', color: color }}>&#9679;</span>
}

const OnlineStatusWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`

const OnlineStatus = ({ online }) => {
  return (
    <OnlineStatusWrapper>
      <ConnectionIndicator connected={online} />
      <span style={{ marginLeft: '0.3em' }}>
        {online ? 'online' : 'offline'}
      </span>
    </OnlineStatusWrapper>
  )
}

const ModuleListPart = ({ values, showTiles, itemType, onDelete, header }) => {
  const history = useHistory()
  const { t } = useTranslation()

  let title = ''
  let url = ''

  switch (itemType) {
    case 'CentralModule':
      title = 'centralModule'
      url = 'modules'
      break
    case 'FieldModule':
      title = 'fieldModule'
      url = 'modules'
      break
    case 'Valve':
      title = 'valve'
      url = 'valves'
      break
    case 'Sensor':
      title = 'sensor'
      url = 'sensors'
      break
    default:
      break
  }

  const noResults = t('common:noResults', {
    results: t(`models:${title}`, { count: 0 }),
  })

  const isModule = ['CentralModule', 'FieldModule'].includes(itemType)
  const showOnlineStatus = isModule

  if (values.length == 0) {
    return (
      <>
        {header}
        <p>{noResults}</p>
      </>
    )
  }

  return showTiles ? (
    <>
      {header}
      <Grid>
        {values.map(({ id, name, wateringModuleId, online }, index) => {
          return (
            <GridItem key={index}>
              <Tile
                title={name}
                onClick={() => history.push(`/${url}/${id}`)}
                centered
                actions={
                  <Action
                    onClick={(e) => {
                      history.push(
                        `/map?moduleId=${isModule ? id : wateringModuleId}`
                      )
                      e.stopPropagation()
                    }}
                  >
                    <Icon name="map-marker-alt" color="#fff" border="#fff" />
                  </Action>
                }
              >
                {showOnlineStatus && <OnlineStatus online={online} />}
              </Tile>
            </GridItem>
          )
        })}
      </Grid>
    </>
  ) : (
    <Table spacious divided>
      <Head>
        <Row>
          <Header colSpan={2}>{t(`models:${title}`, { count: 0 })}</Header>
        </Row>
      </Head>
      <Body>
        {values.map(({ id, name, wateringModuleId }, index) => {
          return (
            <Row key={index}>
              <Column>
                <TextLink to={`/${url}/${id}`}>{name}</TextLink>
              </Column>
              <Column actions={4}>
                <Action
                  horizontal
                  to={`/map?moduleId=${isModule ? id : wateringModuleId}`}
                >
                  <Icon small name="map-marker-alt" />
                </Action>
                <Action horizontal to={`/${url}/${id}`}>
                  <Icon small name="view" />
                </Action>
                <Action horizontal to={`/${url}/${id}/edit`}>
                  <Icon small name="edit" />
                </Action>
                <Action
                  horizontal
                  onClick={(e) => {
                    onDelete(id)
                    e.stopPropagation()
                  }}
                >
                  <Icon small name="delete" />
                </Action>
              </Column>
            </Row>
          )
        })}
      </Body>
    </Table>
  )
}

export default ModuleListPart
