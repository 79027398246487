import parseISO from 'date-fns/parseISO'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'
import { ApiError } from '../../lib/api'
import { valves as valvesResource } from '../../redux/actions/resource'
import { getValveById } from '../../redux/reducers'

import Action from '../../components/Action'
import { StyledForm, FormField } from '../../components/forms'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'

const EditValve = ({ history, match }) => {
  const { t } = useTranslation()
  const valveId = match.params.id
  const valve =
    valveId == null ? {} : useSelector((state) => getValveById(state, valveId))
  const dispatch = useDispatch()
  const projectId = useSelector((state) => state.currentProjectId)

  useEffect(() => {
    if (valveId != null) {
      dispatch(valvesResource.read(valveId))
    }
  }, [dispatch, valveId])

  console.log('valve', valve)

  const { name, description } = valve || {}

  const updateValve = (id, attributes) => {
    return dispatch(valvesResource.update(id, undefined, attributes))
  }

  return (
    <>
      <Toolbar title={name}>
        <Action vertical form="editValve">
          <Icon name="confirm" />
        </Action>
      </Toolbar>

      <Form
        initialValues={{
          name,
          description,
        }}
        onSubmit={(attributes) => {
          // TODO: validate attributes

          console.log('onSubmit', valveId, attributes)

          const result = updateValve(valveId, attributes)

          return result.promise.then((result) => {
            if (result instanceof ApiError) {
              // there was an error => show the errors
              const { errors } = result.json
              const ret = Object.keys(errors).reduce((ret, key) => {
                ret[key] = errors[key].join(', ')
                return ret
              }, {})
              return ret
            } else {
              // there was no error => navigate back to valve
              history.goBack()
            }
          })
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <StyledForm id="editValve" singleColumn onSubmit={handleSubmit}>
              <FormField name="name" label={t('attributes:valve:name')} />
              <FormField
                name="description"
                label={t('attributes:valve:description')}
              />
            </StyledForm>
          )
        }}
      </Form>
    </>
  )
}

export default withRouter(EditValve)
