import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Show from './Show'
import Edit from './Edit'
import EditSensorBasedIrrigation from './sensor_based_irrigation/Edit'

export default () => (
  <Switch>
    <Route path="/valves/:id" exact component={Show} />
    <Route path="/valves/:id/edit" exact component={Edit} />
    <Route
      path="/valves/:id/sensor_based_irrigation"
      exact
      component={EditSensorBasedIrrigation}
    />
  </Switch>
)
