import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  modules as modulesResource,
  replaceCentralModule as replaceCentralModuleResource,
} from '../../redux/actions/resource'
import { getCurrentRole, getModuleById } from '../../redux/reducers'

import Toolbar from '../../components/Toolbar'
import {
  Button,
  FormField,
  FormToggle,
  StyledForm,
} from '../../components/forms'
import { Form } from 'react-final-form'
import { ApiError } from '../../lib/api'

const ReplaceModule = ({ history, match, t }) => {
  const moduleId = match.params.id
  const module = useSelector((state) => getModuleById(state, moduleId))
  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()
  const role = useSelector(getCurrentRole)

  // check if current user is authorized
  if (role !== 'admin' && role !== 'irrigation_maintainer') {
    return (
      <>
        <p>Sie haben leider keine Berechtigung für diese Seite.</p>
      </>
    )
  }

  if (module.type === 'FieldModule') {
    return (
      <>
        <Toolbar title="Feldmodul tauschen" />
        <p>
          Das Austauschen von Feldmodulen wird aktuell noch nicht unterstützt.
        </p>
      </>
    )
  }
  const replaceModule = (attributes) =>
    dispatch(replaceCentralModuleResource.create({ moduleId }, attributes))

  return (
    <>
      <Toolbar title="Zentralmodul tauschen" />
      <p>
        Um das Zentralmodul "{module.name}" mit Seriennummer{' '}
        {module.serialNumber} zu tauschen, geben Sie bitte die Seriennummer des
        neuen Zentralmoduls ein.
      </p>
      <p>
        Es werden alle Feldmodule des alten Zentralmoduls dem neuen Zentralmodul
        zugewiesen.
      </p>
      <p>
        Wenn das neue Zentralmodul noch nicht dem Projekt des alten
        Zentralmoduls zugewiesen war, wird es automatisch dem Projekt
        zugewiesen.
      </p>
      <Form
        initialValues={{ new_serial_number: '' }}
        onSubmit={(attributes) => {
          replaceModule(attributes).promise.then((result) => {
            if (result instanceof ApiError) {
              // error
              // TODO: get translated message from backend when backend has
              //       user friendly error messages
              //const { message } = result.json
              setErrors(
                'Zentralmodul konnte nicht getauscht werden, bitte prüfen ob die Seriennummer richtig eingegeben wurde.'
              )
            } else {
              // success
              // reload all modules of project
              dispatch(modulesResource.list({ projectId: module.projectId }))
              // go back
              history.goBack()
            }
          })
        }}
      >
        {({ handleSubmit }) => (
          <StyledForm divided id="editProgram" onSubmit={handleSubmit}>
            {errors && <p style={{ color: 'red' }}>{errors}</p>}
            <FormField
              name="new_serial_number"
              label={'Seriennummer des neuen Zentralmoduls'}
            />
            <Button autoSize>{t(`actions:module.swapModule`)}</Button>
          </StyledForm>
        )}
      </Form>
    </>
  )
}

export default withRouter(withTranslation()(ReplaceModule))
