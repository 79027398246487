import { FormField, ValidationError } from '../../../components/forms'
import { thresh0Label } from './utils'
import React from 'react'

export const SingleThresholdIrrigationFields = ({
  errors,
  touched,
  t,
  sensorUnit,
}) => {
  return (
    <>
      <p>{t('attributes:valve:sensorBasedIrrigation.hints.thresh0')}</p>
      <FormField
        name="sensorBasedIrrigationAttributes[thresh0]"
        label={thresh0Label(sensorUnit, t)}
      />
      {errors.sensorBasedIrrigationAttributes &&
        errors.sensorBasedIrrigationAttributes.thresh0 &&
        touched['sensorBasedIrrigationAttributes[thresh0]'] && (
          <div styles="column-span: all;">
            <ValidationError>
              {errors.sensorBasedIrrigationAttributes.thresh0}
            </ValidationError>
          </div>
        )}
    </>
  )
}

export default SingleThresholdIrrigationFields
