import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { sensorValues as sensorsResource } from '../../redux/actions/resource'
import {
  getCurrentProject,
  getAllValves,
  getAllSensorValues,
} from '../../redux/reducers'

import Action, { Actions } from '../../components/Action'
import Icon from '../../components/Icon'
import ListToolbar from '../../components/ListToolbar'
import ListTitle from '../../components/composed/ListTitle'
import ModuleListPart from './ModuleListPart'

const SensorList = ({ t, moduleId, showTiles }) => {
  const projectId = useSelector(getCurrentProject)?.id
  const sensors = useSelector(getAllSensorValues).filter(
    ({ wateringModuleId }) => wateringModuleId === moduleId
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(sensorsResource.list({ projectId }))
  }, [dispatch])

  const onSearch = (search) => {
    if (projectId) {
      dispatch(sensorsResource.list({ projectId, search }))
    }
  }

  return (
    <div>
      <ModuleListPart
        showTiles={showTiles}
        values={sensors}
        header={
          <ListTitle>
            <span>{t('models:sensor', { count: 2 })}</span>
            {/* <Action to={`/valves/new`}>
              <Icon small name="add" />
            </Action> */}
          </ListTitle>
        }
        itemType="Sensor"
      />
    </div>
  )
}

export default withTranslation()(withTheme(SensorList))
