import { call, put, takeEvery } from 'redux-saga/effects'

import { WEB_PUSH_REQUEST, webPushSuccess } from '../actions'

const applicationServerKey = process.env.VAPID_PUBLIC_KEY

const SERVER_PATH = '/web_push_subscriptions'

const serializeSubscription = (subscription) => {
  const {
    endpoint,
    keys: { auth, p256dh },
  } = subscription.toJSON()
  return JSON.stringify({ endpoint, auth, p256dh })
}

function* manageSubscription(api, registration, { payload: enable }) {
  let subscription = null

  if (enable) {
    subscription = yield call([registration.pushManager, 'subscribe'], {
      userVisibleOnly: true,
      applicationServerKey,
    })
    yield api.create(SERVER_PATH, serializeSubscription(subscription))
    yield put(webPushSuccess(true))
  } else {
    subscription = yield registration.pushManager.getSubscription()
    yield subscription.unsubscribe()
    yield put(webPushSuccess(false))
    yield api.destroy(SERVER_PATH, serializeSubscription(subscription))
  }
}

export default function* watchForPushSubscription(api) {
  if (navigator.serviceWorker) {
    const registration = yield navigator.serviceWorker.ready
    if (registration.pushManager != null) {
      const subscription = yield registration.pushManager.getSubscription()

      if (subscription) {
        yield put(webPushSuccess(true))
      }

      yield takeEvery(WEB_PUSH_REQUEST, manageSubscription, api, registration)
    }
  }
}
