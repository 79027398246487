import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'

import { ApiError } from '../../lib/api'
import { sensorValues as sensorsResource } from '../../redux/actions/resource'
import { getSensorValueById, getCurrentProjectId } from '../../redux/reducers'

import {
  FormField,
  StyledForm,
  Dropdown,
  FormToggle,
} from '../../components/forms'
import Icon from '../../components/Icon'
import Action from '../../components/Action'
import Toolbar from '../../components/Toolbar'

const EditSensor = ({ history, match, t }) => {
  const sensorId = match.params.id
  const sensor =
    sensorId == null
      ? {}
      : useSelector((state) => getSensorValueById(state, sensorId))
  const currentProjectId = useSelector(getCurrentProjectId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (sensorId != null) {
      dispatch(sensorsResource.read(sensorId))
    }
  }, [])

  const updateSensor = (id, attributes) =>
    dispatch(sensorsResource.update(id, undefined, attributes))

  const {
    id,
    name,
    showOnMap,
    showOnDashboard,
    availableForSensorBasedIrrigation,
    availableForSensorThresholdIrrigation,
    sortOrder,
  } = sensor || {}

  return (
    <>
      <Toolbar title={name}>
        <Action form="editSensor">
          <Icon name="confirm" />
        </Action>
      </Toolbar>
      <Form
        initialValues={{
          name,
          showOnMap,
          availableForSensorBasedIrrigation,
          availableForSensorThresholdIrrigation,
          showOnDashboard,
          sortOrder,
        }}
        onSubmit={(attributes) => {
          const result = updateSensor(id, attributes)
          return result.promise.then((result) => {
            if (result instanceof ApiError) {
              const { errors } = result.json
              const ret = Object.keys(errors).reduce((ret, key) => {
                ret[key] = errors[key].join(', ')
                return ret
              }, {})
              return ret
            } else {
              history.goBack()
            }
          })
        }}
      >
        {({ handleSubmit }) => (
          <StyledForm divided id="editSensor" onSubmit={handleSubmit}>
            <FormField name="name" label={t('attributes:sensor.name')} />
            <FormField
              name="showOnMap"
              noLine
              label={t('attributes:sensor.showOnMap')}
              component={FormToggle}
            />
            <p>
              Admins und Beregnungswarte sehen auf dem Dashboard alle Sensoren,
              bei denen die Option{' '}
              <span style={{ fontWeight: 'bold' }}>
                {t('attributes:sensor.showOnDashboard')}
              </span>{' '}
              aktiviert ist. Normale Benutzer sehen Sensoren nur, wenn sie als
              Benutzer eingetragen sind.
            </p>
            <FormField
              name="showOnDashboard"
              noLine
              label={t('attributes:sensor.showOnDashboard')}
              component={FormToggle}
            />
            <FormField
              name="availableForSensorBasedIrrigation"
              noLine
              label={t('attributes:sensor.availableForSensorBasedIrrigation')}
              component={FormToggle}
            />
            <FormField
              name="availableForSensorThresholdIrrigation"
              noLine
              label={t(
                'attributes:sensor.availableForSensorThresholdIrrigation'
              )}
              component={FormToggle}
            />
            <FormField
              name="sortOrder"
              label={t('attributes:sensor.sortOrder')}
            />
          </StyledForm>
        )}
      </Form>
    </>
  )
}

export default withRouter(withTranslation()(EditSensor))
