import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  valves as valvesResource,
  valveGroups as valveGroupsResource,
} from '../../redux/actions/resource'
import {
  arrayFilter,
  getValveGroupById,
  getAllValves,
  getCurrentProjectId,
} from '../../redux/reducers'

import Action from '../../components/Action'
import { Button } from '../../components/forms'
import { confirm } from '../../components/Alert'
import Icon from '../../components/Icon'
import Table, { Body, Column, Header, Row } from '../../components/Table'
import Toolbar from '../../components/Toolbar'
import ListTitle from '../../components/composed/ListTitle'

import ValveList from './ValveList'

const Show = ({ history, match, t }) => {
  const valveGroupId = match.params.id
  const projectId = useSelector(getCurrentProjectId)
  const valveGroup = useSelector((state) =>
    getValveGroupById(state, valveGroupId)
  )
  const { name, duration, wateringCoefficient, currentValue, valveIds } =
    valveGroup || {}

  const valves = useSelector((state) =>
    getAllValves(state, arrayFilter(valveIds))
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(valveGroupsResource.read(valveGroupId))
    dispatch(valvesResource.list({ projectId }))
  }, [dispatch])

  const onSearch = (search) => {
    dispatch(valvesResource.list({ projectId, search }))
  }

  const onDeleteValve = async (valveId) => {
    if (await confirm('Möchtest du dieses Ventil wirklich entfernen?')) {
      const updatedValveIds = valveIds.filter((id) => id != valveId)
      dispatch(
        valveGroupsResource.update(valveGroupId, undefined, {
          valveGroup: {
            valveIds: [...updatedValveIds],
          },
        })
      )
    }
  }

  return (
    <>
      <Toolbar title={name}>
        <Action to={`/valve_groups/${valveGroupId}/edit`}>
          <Icon name="edit" />
        </Action>
      </Toolbar>
      <Table attributes>
        <Body>
          <Row>
            <Header scope="row">{t('attributes:valveGroup.name')}</Header>
            <Column>{name}</Column>
          </Row>
          <Row>
            <Header scope="row">{t('attributes:valveGroup.duration')}</Header>
            <Column>{t('format:minutes', { count: duration })}</Column>
          </Row>
          <Row>
            <Header scope="row">
              {t('attributes:valveGroup.wateringCoefficient')}
            </Header>
            <Column>
              {t('format:float', { number: wateringCoefficient })}
            </Column>
          </Row>
          <Row>
            <Header scope="row">
              {t('attributes:valveGroup.totalDuration')}
            </Header>
            <Column>
              {t('format:minutes', { count: duration * wateringCoefficient })}
            </Column>
          </Row>
          {/* <Row>
            <Header scope="row">{t('attributes:valveGroup.state')}</Header>
            <Column>
              {t(
                `attributes:valveGroup.states.${
                  currentValue == null ? 2 : currentValue
                }`
              )}
            </Column>
          </Row>
          <Row>
            <Column colSpan={2}>
              <Button onClick={() => console.log('click!')}>
                {t(`actions:valveGroup.states.${+currentValue || 0}`)}
              </Button>
            </Column>
          </Row> */}
        </Body>
      </Table>
      <ListTitle>
        <span>{t('models:valve', { count: 0 })}</span>
        <Action to={`/valve_groups/${valveGroupId}/add_valve`}>
          <Icon small name="add" />
        </Action>
      </ListTitle>
      <ValveList
        valves={valves}
        onSearch={onSearch}
        onTileClick={(id) =>
          history.push(`/valves/${id}?valveGroupId=${valveGroupId}`)
        }
        tileActions={(id) => {
          return (
            <Action
              onClick={(e) => {
                onDeleteValve(id)
                e.stopPropagation()
              }}
            >
              <Icon name="delete" color="#fff" border="#fff" />
            </Action>
          )
        }}
      >
        {(id) => {
          return (
            <>
              <Action horizontal to={`/valves/${id}`}>
                <Icon small name="view" />
              </Action>
              <Action horizontal to={`/valves/${id}/edit`}>
                <Icon small name="edit" />
              </Action>
              <Action horizontal onClick={() => onDeleteValve(id)}>
                <Icon small name="delete" />
              </Action>
            </>
          )
        }}
      </ValveList>
    </>
  )
}

export default withRouter(withTranslation()(Show))
