import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Map from './Map'

export default () => (
  <Switch>
    <Route path="/map" exact component={Map} />
  </Switch>
)
