import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  valves as valvesResource,
  valveGroups as valveGroupsResource,
} from '../../redux/actions/resource'
import {
  arrayFilter,
  getAllValves,
  getCurrentProjectId,
  getValveGroupById,
} from '../../redux/reducers'

import Action from '../../components/Action'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'

import ValveList from './ValveList'

const AddValve = ({ history, match, t }) => {
  const valveGroupId = match.params.id
  const projectId = useSelector(getCurrentProjectId)
  const valveGroup = useSelector((state) =>
    getValveGroupById(state, valveGroupId)
  )
  const valveIds = valveGroup.valveIds
  // filter out valves that are already part of the group
  const valves = useSelector((state) =>
    getAllValves(state, arrayFilter(valveIds, false))
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(valveGroupsResource.read(valveGroupId))
    dispatch(valvesResource.list({ projectId }))
  }, [dispatch])

  const onSearch = (search) => {
    dispatch(valvesResource.list({ projectId, search }))
  }

  const addValve = (id) => {
    dispatch(
      valveGroupsResource.update(valveGroupId, undefined, {
        valveGroup: {
          valveIds: [...valveIds, id],
        },
      })
    ).promise.then(() => {
      history.push(`/valve_groups/${valveGroupId}`)
    })
  }

  return (
    <>
      <Toolbar title="Ventilauswahl" />
      <ValveList valves={valves} onSearch={onSearch} onTileClick={addValve}>
        {(id) => {
          return (
            <Action horizontal onClick={() => addValve(id)}>
              <Icon small name="add" />
            </Action>
          )
        }}
      </ValveList>
    </>
  )
}

export default withRouter(withTranslation()(AddValve))
