import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { profile as profileResource } from '../../redux/actions/resource'
import {
  getProfile,
  getStartProjectSetting,
  getLanguageSetting,
  getNotificationSettings,
  getUseTilesSetting,
} from '../../redux/reducers'

import Action from '../../components/Action'
import Icon from '../../components/Icon'
import Table, { Body, Column, Header, Row } from '../../components/Table'
import Toolbar from '../../components/Toolbar'

const Show = ({ history }) => {
  const { t } = useTranslation()
  const profile = useSelector(getProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(profileResource.read())
  }, [dispatch])

  const { firstName, lastName, email, projectRoles } = profile || {}

  const startProject = useSelector(getStartProjectSetting)
  const language = useSelector(getLanguageSetting)
  const useTiles = useSelector(getUseTilesSetting)
  const notificationSettings = useSelector(getNotificationSettings)

  const currentLanguage = t('attributes:user.settings.languageOptions').find(
    ({ id }) => id === language
  )
  const currentUseTiles = t('attributes:user.settings.useTilesOptions').find(
    ({ id }) => id === useTiles
  )
  const currentNotificationSettings = Object.entries(notificationSettings)
    .filter(([_, enabled]) => enabled)
    .map(([setting]) =>
      t(`attributes:user.settings.notificationsOptions.${setting}`)
    )

  return (
    <>
      <Toolbar
        title={t('models:profile')}
        onBack={() => history.push('/dashboard')}
      >
        <Action to="/profile/edit">
          <Icon name="edit" />
        </Action>
      </Toolbar>
      <Table attributes>
        <Body indented>
          <Row>
            <Column colSpan={2}>
              {firstName} {lastName}
            </Column>
          </Row>
          <Row>
            <Column colSpan={2}>{email}</Column>
          </Row>
        </Body>

        <Body>
          <Row>
            <Header colSpan={2}>Projektrollen</Header>
          </Row>
        </Body>
        <Body indented>
          {(projectRoles ?? []).map(({ name, role }, index) => {
            return (
              <Row key={index}>
                <Column>{name}</Column>
                <Column>{t(`attributes:user:roleOptions:${role}`)}</Column>
              </Row>
            )
          })}
        </Body>

        <Body>
          <Row>
            <Header colSpan={2}>Einstellungen</Header>
          </Row>
        </Body>
        <Body indented>
          <Row>
            <Column>{t('attributes:user.settings.startProject')}</Column>
            <Column>{startProject?.name}</Column>
          </Row>
          <Row>
            <Column>{t('attributes:user.settings.language')}</Column>
            <Column>{currentLanguage?.name}</Column>
          </Row>
          <Row>
            <Column>{t('attributes:user.settings.useTiles')}</Column>
            <Column>{currentUseTiles?.name}</Column>
          </Row>
          <Row>
            <Column>{t('attributes:user.settings.notifications')}</Column>
            <Column>{currentNotificationSettings.join(', ')}</Column>
          </Row>
        </Body>
      </Table>
    </>
  )
}

export default withRouter(Show)
