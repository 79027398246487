import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { projects } from '../../redux/actions/resource'
import { getProjectById } from '../../redux/reducers'

import Action from '../../components/Action'
import Icon from '../../components/Icon'
import Table, { Body, Column, Header, Row } from '../../components/Table'
import Toolbar from '../../components/Toolbar'

const Show = ({ history, match, t }) => {
  const projectId = match.params.id
  const project = useSelector((state) => getProjectById(state, projectId))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(projects.read(projectId))
  }, [dispatch])

  return (
    <>
      <Toolbar title={project.name} onBack={() => history.goBack() }>
        <Action to={`/projects/${projectId}/edit`}>
          <Icon name="edit" />
        </Action>
      </Toolbar>
      <Table>
        <Body>
          <Row>
            <Header quiet scope="row">
              {t('attributes:project.name')}:
            </Header>
            <Column>{project.name}</Column>
          </Row>
        </Body>
      </Table>
    </>
  )
}

export default withRouter(withTranslation()(withTheme(Show)))
