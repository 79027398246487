import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Edit from './Edit'
import Show from './Show'
import CreateValueGroup from '../valve_groups/Edit'

export default () => (
  <Switch>
    <Route path="/shifts/:id" exact component={Show} />
    <Route path="/shifts/:id/edit" exact component={Edit} />
    <Route
      path="/shifts/:shiftId/valve_groups/new"
      exact
      component={CreateValueGroup}
    />
  </Switch>
)
