import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { valves } from '../../redux/actions/resource'
import { getValveById } from '../../redux/reducers'
import Action from '../../components/Action'
import { Button, FormField, FormGroup } from '../../components/forms'
import Icon from '../../components/Icon'
import Table, { Body, Column, Header, Row } from '../../components/Table'
import ListTitle from '../../components/composed/ListTitle'
import Toolbar from '../../components/Toolbar'
import ProgramShiftTable from '../../components/composed/ProgramShiftTable'
import HideForRoles from '../../components/auth/HideForRoles'
import ValveOwnersForm from './owners/Form'
import ShowSensorBasedIrrigation from './sensor_based_irrigation/Show'
import TemporarySuspensionForm from './temporary_suspensions/Form'
import ErrorBoundary from '../../lib/ErrorBoundary'

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 1rem;
  }

  ${FormGroup} {
    margin-bottom: 0;
    flex: 75px 0 0;

    input[type='number'] {
      text-align: right;
      padding: 1px 2px;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      -moz-appearance: textfield;
    }
  }
`

const Label = styled.span``

const StateWrapper = styled.div`
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 1.5rem;
  }

  ${Label} {
    margin-left: 0.5rem;
  }
`

const Show = ({ match, t, theme }) => {
  const valveId = match.params.id

  const useQuery = () => new URLSearchParams(useLocation().search)
  const valveGroupId = useQuery().get('valveGroupId')
  const valve = useSelector((state) => getValveById(state, valveId))
  const dispatch = useDispatch()

  useEffect(() => {
    if (valveId) {
      dispatch(valves.read(valveId))
    }
  }, [dispatch])

  const updateValve = (attributes) =>
    dispatch(valves.update(valveId, undefined, attributes))

  const {
    name,
    currentIsSuspended,
    currentValue,
    pressure,
    programs,
    closesAt,
    sensorBasedIrrigation,
  } = valve || {}

  const { sensorName, sensorUnit } = sensorBasedIrrigation || {}

  const openUntil = t(`attributes:valve:closesAt`, { closesAt })

  return (
    <>
      <Toolbar title={name}>
        <Action to={`/valves/${valveId}/edit`}>
          <Icon name="edit" />
        </Action>
      </Toolbar>

      <Table attributes>
        <Body>
          <Row>
            <Header scope="row">{t('attributes:valve.name')}</Header>
            <Column>{name}</Column>
          </Row>
          <Row>
            <Header scope="row">{t('attributes:valve.state')}</Header>
            <Column>
              <StateWrapper>
                <Icon
                  small
                  bordered={false}
                  rotate={currentIsSuspended ? 90 : undefined}
                  name={currentIsSuspended ? 'manual' : 'sync'}
                />
                <Icon
                  small
                  bordered={false}
                  name={`circle-${currentValue || 0}`}
                />
                {!!pressure && (
                  <Icon
                    small
                    bordered={false}
                    name="tint"
                    color={theme.colors.water}
                  />
                )}
                {closesAt && (
                  <Icon small bordered={false} name="hourglass-half" />
                )}
                <div>{closesAt && openUntil}</div>
              </StateWrapper>
            </Column>
          </Row>
          <HideForRoles roles={['farmer']}>
            <Row>
              <Column colSpan={2}>
                <Form
                  initialValues={{ duration: 60 }}
                  onSubmit={({ duration }, { reset }) => {
                    updateValve({
                      value: +!currentValue,
                      duration,
                    }).promise.then(reset)
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      {/* Prevent implicit submission of the form */}
                      <button
                        type="submit"
                        disabled
                        style={{ display: 'none' }}
                        aria-hidden="true"
                      ></button>
                      <DurationWrapper>
                        <Button
                          smaller
                          type="button"
                          onClick={(e) => {
                            updateValve({ isSuspended: +!currentIsSuspended })
                            e.preventDefault()
                          }}
                        >
                          {t(`actions:valve.modes.${+currentIsSuspended || 0}`)}
                        </Button>
                        {!(!currentIsSuspended || currentValue == null) && (
                          <>
                            <Button smaller type="submit">
                              {t(`actions:valve.states.${+currentValue || 0}`)}
                            </Button>
                            <FormField
                              type="number"
                              name="duration"
                              label="Dauer"
                              suffix="Min"
                            />
                          </>
                        )}
                      </DurationWrapper>
                    </form>
                  )}
                </Form>
              </Column>
            </Row>
          </HideForRoles>
        </Body>
      </Table>

      <ListTitle>
        <span>{t('attributes:valve:temporarySuspension:title')}</span>
      </ListTitle>
      <TemporarySuspensionForm valveId={valveId} />

      <ListTitle>
        <span>{t('routes:valves.programsAndShifts')}</span>
      </ListTitle>
      <ProgramShiftTable programs={programs} />

      <ListTitle>
        <span>{t('attributes:valve:sensorBasedIrrigation:title')}</span>
      </ListTitle>
      <ShowSensorBasedIrrigation valve={valve} valveGroupId={valveGroupId} />

      <HideForRoles roles={['farmer']}>
        <ListTitle>
          <span>{t('attributes:valve:owners:title')}</span>
        </ListTitle>
        <ValveOwnersForm valve={valve} />
      </HideForRoles>
    </>
  )
}

export default withRouter(withTranslation()(withTheme(Show)))
