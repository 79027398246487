import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'react-final-form'

import {
  profile as profileResource,
  projects as projectsResource,
} from '../../redux/actions/resource'
import { webPushRequest } from '../../redux/actions/webPush'
import {
  getProfile,
  getAllProjects,
  webPushEnabled,
} from '../../redux/reducers'

import {
  FormField,
  FormToggle,
  StyledForm,
  Dropdown,
} from '../../components/forms'
import Icon from '../../components/Icon'
import Action from '../../components/Action'
import Toolbar from '../../components/Toolbar'
import { H3 } from '../../components/type'

const Edit = ({ history }) => {
  const { t, i18n } = useTranslation()
  const profile = useSelector(getProfile)
  const projects = useSelector(getAllProjects)
  const pushNotificationsEnabled = useSelector(webPushEnabled)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(profileResource.read())
    dispatch(projectsResource.list())
  }, [dispatch])

  const { firstName, lastName, email, settings } = profile || {}

  const updateProfile = (attributes) => {
    const { settings, ...user } = attributes
    const promises = []
    if (attributes.pushNotificationsEnabled !== pushNotificationsEnabled) {
      promises.push(
        dispatch(webPushRequest(attributes.pushNotificationsEnabled)).promise
      )
    }
    delete user.pushNotificationsEnabled
    promises.push(
      dispatch(
        profileResource.update(undefined, undefined, {
          ...user,
          settingsAttributes: settings,
        })
      ).promise
    )

    return Promise.all(promises)
  }

  return (
    <>
      <Toolbar title={t('editThing', { thing: t('models:profile') })}>
        <Action form="editProfile">
          <Icon name="confirm" />
        </Action>
      </Toolbar>
      <Form
        initialValues={{
          firstName,
          lastName,
          email,
          settings,
          pushNotificationsEnabled,
        }}
        onSubmit={(attributes) =>
          updateProfile(attributes)
            .then(() => i18n.changeLanguage(attributes?.settings?.language))
            .then(() => {
              history.push(`/profile`)
            })
        }
      >
        {({ handleSubmit }) => (
          <StyledForm divided id="editProfile" onSubmit={handleSubmit}>
            <H3 pulled>Persönliche Daten</H3>
            <FormField
              name="firstName"
              label={t('attributes:user.firstName')}
            />
            <FormField name="lastName" label={t('attributes:user.lastName')} />
            <FormField
              type="email"
              name="email"
              label={t('attributes:user.email')}
            />
            <H3 pulled>Einstellungen</H3>
            <FormField
              name="settings.startProjectId"
              label={t('attributes:user.settings.startProject')}
              options={projects}
              component={Dropdown}
            />
            <FormField
              name="settings.language"
              label={t('attributes:user.settings.language')}
              options={t('attributes:user.settings.languageOptions')}
              component={Dropdown}
            />
            <FormField
              name="settings.useTiles"
              label={t('attributes:user.settings.useTiles')}
              options={t('attributes:user.settings.useTilesOptions')}
              component={Dropdown}
            />
            <H3 pulled>Benachrichtigungen</H3>
            <FormField
              name="settings.emailNotificationsEnabled"
              noLine
              label={t('attributes:user.settings.notificationsOptions.email')}
              component={FormToggle}
            />
            {/* <FormField
              name="settings.smsNotificationsEnabled"
              noLine
              label={t('attributes:user.settings.notificationsOptions.sms')}
              component={FormToggle}
            /> */}
            <FormField
              name="pushNotificationsEnabled"
              noLine
              label={t('attributes:user.settings.notificationsOptions.push')}
              component={FormToggle}
            />
          </StyledForm>
        )}
      </Form>
    </>
  )
}

export default withRouter(Edit)
