import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { projects as projectsResource } from '../../redux/actions/resource'
import { useSwitchProject } from '../../redux/actions/currentProject'

import { getAllProjects } from '../../redux/reducers'

import Action from '../../components/Action'
import { confirm } from '../../components/Alert'
import Icon from '../../components/Icon'
import ListToolbar from '../../components/ListToolbar'
import Table, { Body, Column, Head, Header, Row } from '../../components/Table'
import Tile, { Grid, GridItem } from '../../components/Tile'
import { TextLink } from '../../components/type'
import Toolbar from '../../components/Toolbar'

const ProjectList = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const projects = useSelector(getAllProjects)
  const dispatch = useDispatch()
  const setProjectAndNavigate = useSwitchProject()

  useEffect(() => {
    dispatch(projectsResource.list())
  }, [dispatch])

  const onSearch = (search) => {
    dispatch(projectsResource.list({ search }))
  }

  const onDeleteProject = async (id) => {
    if (await confirm('Möchtest du dieses Projekt wirklich löschen?')) {
      dispatch(projectsResource.destroy(id))
    }
  }

  return (
    <>
      <Toolbar title={t('models:project', { count: 0 })}>
        <Action to="/projects/new">
          <Icon name="add" />
        </Action>
      </Toolbar>
      <ListToolbar onSearch={onSearch}>
        {({ showTiles }) => {
          return showTiles ? (
            <Grid>
              {(projects || []).map(({ name, id }, index) => {
                return (
                  <GridItem key={index}>
                    <Tile
                      title={name}
                      onClick={() => history.push(`/projects/${id}`)}
                      actions={
                        <Action onClick={setProjectAndNavigate(id, '/map')}>
                          <Icon
                            name="map-marker-alt"
                            color="#fff"
                            border="#fff"
                          />
                        </Action>
                      }
                    />
                  </GridItem>
                )
              })}
            </Grid>
          ) : (
            <Table spacious divided>
              <Head>
                <Row>
                  <Header colSpan={2}>
                    {t('models:project', { count: 0 })}
                  </Header>
                </Row>
              </Head>
              <Body>
                {(projects || []).map(({ id, name }, index) => {
                  return (
                    <Row key={index}>
                      <Column>
                        <TextLink to={`/projects/${id}`}>{name}</TextLink>
                      </Column>
                      <Column actions={4}>
                        <Action
                          horizontal
                          onClick={setProjectAndNavigate(id, '/map')}
                        >
                          <Icon small name="map-marker-alt" />
                        </Action>
                        <Action horizontal to={`/projects/${id}`}>
                          <Icon small name="view" />
                        </Action>
                        <Action horizontal to={`/projects/${id}/edit`}>
                          <Icon small name="edit" />
                        </Action>
                        <Action horizontal onClick={() => onDeleteProject(id)}>
                          <Icon small name="delete" />
                        </Action>
                      </Column>
                    </Row>
                  )
                })}
              </Body>
            </Table>
          )
        }}
      </ListToolbar>
    </>
  )
}

export default ProjectList
