const storage = window.sessionStorage

export const loadState = () => {
  try {
    const serializedState = storage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    storage.setItem('state', serializedState)
  } catch {
    // ignore for now
  }
}
