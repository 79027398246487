import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { valves as valvesResource } from '../../redux/actions/resource'
import { getCurrentProject, getAllValves } from '../../redux/reducers'

import Action, { Actions } from '../../components/Action'
import Icon from '../../components/Icon'
import ListToolbar from '../../components/ListToolbar'
import ListTitle from '../../components/composed/ListTitle'
import ModuleListPart from './ModuleListPart'

const ValveList = ({ t, moduleId, showTiles }) => {
  const projectId = useSelector(getCurrentProject)?.id
  const relatedValves = useSelector(getAllValves).filter(
    ({ wateringModuleId }) => wateringModuleId === moduleId
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(valvesResource.list({ projectId }))
  }, [dispatch])

  const onSearch = (search) => {
    if (projectId) {
      dispatch(valvesResource.list({ projectId, search }))
    }
  }

  return (
    <ModuleListPart
      values={relatedValves}
      header={
        <ListTitle>
          <span>{t('models:valve', { count: 0 })}</span>
          {/* <Action to={`/valves/new`}>
            <Icon small name="add" />
          </Action> */}
        </ListTitle>
      }
      showTiles={showTiles}
      itemType="Valve"
    />
  )
}

export default withTranslation()(withTheme(ValveList))
