import { call, take, put, fork, cancel } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { createConsumer } from '@rails/actioncable'
import {
  userChannelConnected,
  userChannelDisconnected,
  userChannelConnectionError,
} from '../actions/cable'
import { fetchResourceSuccess } from '../actions/resource'
import { getStoredToken } from '../../lib/auth'
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from '../actions/authorization'

const CABLE_URL = process.env.CABLE_URL || 'http://localhost:3000/cable'

function buildCableURLWithJWT() {
  return `${CABLE_URL}?jwt_token=${getStoredToken()}`
}

function createUserChannelSubscriptions(consumer) {
  return eventChannel((emit) => {
    const subscription = consumer.subscriptions.create(
      { channel: 'UserChannel' },
      {
        connected: (_) => emit(userChannelConnected()),
        received: (data) => emit(data),
        disconnected: (_) => emit(userChannelDisconnected()),
      }
    )

    return (_) => subscription.disconnect()
  })
}

function* actionCableEventLoop(consumer, userChannelSubscription) {
  while (true) {
    try {
      const { type, payload, resource } = yield take(userChannelSubscription)

      if (type === userChannelConnected().type) {
        yield put(userChannelConnected())
      }

      if (type === userChannelDisconnected().type) {
        yield put(userChannelDisconnected())
      }

      if (resource?.operation === 'update') {
        yield put(fetchResourceSuccess(resource, payload, true))
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export default function* actionCableConnectionFlow(Client, Message) {
  yield call(function* () {
    while (true) {
      // wait for login
      yield take(SIGN_IN_SUCCESS)

      try {
        const consumer = createConsumer(buildCableURLWithJWT)

        const userChannelSubscription = yield call(
          createUserChannelSubscriptions,
          consumer
        )

        // start ActionCable event loop in background that consumes events from UserChannel
        const actionCableEventLoopTask = yield fork(
          actionCableEventLoop,
          consumer,
          userChannelSubscription
        )

        // wait for sign out
        yield take(SIGN_OUT_SUCCESS) // take USER_CHANNEL_CONNECTION_LOST here

        // cancel running tasks
        yield cancel(actionCableEventLoopTask)

        // disconnect ActionCable
        consumer.disconnect()
        // update state of action cable connection to be able to show disconnected state
      } catch (e) {
        console.error('Error connecting to UserChannel', e)
        yield put(userChannelConnectionError({ message: e.errorMessage }))
      }

      yield put(userChannelDisconnected())
    }
  })
}
