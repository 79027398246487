import React from 'react'
import { useSelector } from 'react-redux'
import { getSensorValueById } from '../../../../redux/reducers'
import { withTranslation } from 'react-i18next'

function CurrentHumidityValue({ valve, t }) {
  if (valve === null || valve === undefined) {
    return <></>
  }
  const { sensorBasedIrrigation } = valve || {}
  const { sensorValueId } = sensorBasedIrrigation || {}
  const sensorValue = useSelector((state) =>
    getSensorValueById(state, sensorValueId)
  )
  const { currentValue } = sensorValue || {}
  const { value, timestamp } = currentValue || {}

  return (
    <div style={{ display: 'flex' }}>
      <span style={{ fontWeight: 'bold', paddingRight: '1em' }}>
        {t('attributes:valve:sensorBasedIrrigation:humidity')}
      </span>
      {value ? (
        <>
          <span style={{ marginRight: '1em' }}>{value}hPa</span>
          <span style={{ color: 'lightgray' }}>
            (
            {t(`attributes:valve:sensorBasedIrrigation:timestamp`, {
              timestamp,
            })}
            )
          </span>
        </>
      ) : (
        <span>-</span>
      )}
    </div>
  )
}

export default withTranslation()(CurrentHumidityValue)
