import React from 'react'

import { Route, Switch } from 'react-router-dom'
import Login from './Login'
import RequestPasswordReset from './RequestPasswordReset'
import ChangePassword from './ChangePassword'

// export default Login

export default () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/login/password/new" exact component={RequestPasswordReset} />
    <Route
      path="/login/password/edit/:resetPasswordToken"
      exact
      component={ChangePassword}
    />
    {/* http://localhost:3000/password/edit/r5aaYEwTwGXdyy3qy7tG */}
  </Switch>
)
