import throttle from 'lodash/throttle'

import { ConnectedRouter } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { connect, Provider } from 'react-redux'

import { Redirect, Route, Switch } from 'react-router-dom'

import '../i18n'

import configureStore, { history, loadState, saveState } from '../redux/stores'

import { notificationReceived, dismissFlashMessage } from '../redux/actions'
import { notifications } from '../redux/actions/resource'

import { getCurrentUser, getAllFlashMessages } from '../redux/reducers'

import Layout from '../components/Layout'

import Login from './login'
import Dashbard from './dashboard'
import Events from './events'
import Notifications from './notifications'
import Modules from './modules'
import Map from './map'
import Profile from './profile'
import Programs from './programs'
import Projects from './projects'
import Sensors from './sensors'
import Shifts from './shifts'
import ValveGroups from './valve_groups'
import Valves from './valves'

import FlashMessage from './../components/FlashMessage'
import ErrorBoundary from '../lib/ErrorBoundary'

const ProtectedRoutes = () => (
  <>
    <Route path="/dashboard" component={Dashbard} />
    <Route path="/map" component={Map} />
    <Route path="/modules" component={Modules} />
    <Route path="/programs" component={Programs} />
    <Route path="/projects" component={Projects} />
    <Route path="/sensors" component={Sensors} />
    <Route path="/shifts" component={Shifts} />
    <Route path="/profile" component={Profile} />
    <Route path="/valve_groups" component={ValveGroups} />
    <Route path="/valves" component={Valves} />
    <Route path="/notifications" component={Notifications} />
    <Route path="/events" component={Events} />
    {/* <Route path="/sensor_based_irrigation" component={SensorBasedIrrigation} /> */}
  </>
)

const Routes = ({
  currentUser,
  allFlashMessages,
  notificationReceived,
  fetchNotifications,
  dismissFlashMessage,
}) => {
  if (navigator.serviceWorker) {
    useEffect(() => {
      const handleNotificationMessage = (event) => {
        switch (event.data.type) {
          case 'NOTIFICATION':
            notificationReceived(event.data.notification)
            fetchNotifications()
            break
          default:
            console.log('skipping', event.data.type)
        }
      }
      navigator.serviceWorker.addEventListener(
        'message',
        handleNotificationMessage
      )
      return () => {
        navigator.serviceWorker.removeEventListener(
          'message',
          handleNotificationMessage
        )
      }
    }, [])
  }

  return (
    <ConnectedRouter history={history}>
      <Layout>
        {allFlashMessages.length > 0 && (
          <FlashMessage
            show
            text={allFlashMessages[0].message}
            onCancle={() => dismissFlashMessage(allFlashMessages[0].id)}
          />
        )}
        <Switch>
          <Route path="/login" render={() => <Login />} />
          <Route
            render={() =>
              currentUser ? <ProtectedRoutes /> : <Redirect to="/login" />
            }
          />
        </Switch>
      </Layout>
    </ConnectedRouter>
  )
}

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  allFlashMessages: getAllFlashMessages(state),
})

const mapDispatchToProps = (dispatch) => ({
  notificationReceived: (notification) =>
    dispatch(notificationReceived(notification)),
  fetchNotifications: () => dispatch(notifications.list()),
  dismissFlashMessage: (id) => dispatch(dismissFlashMessage(id)),
})

const ConnectedRoutes = connect(mapStateToProps, mapDispatchToProps)(Routes)

const App = () => {
  const persistedState = loadState()
  const store = configureStore(persistedState)

  store.subscribe(
    throttle(() => {
      const { authorization, currentProjectId, resources } = store.getState()

      saveState({
        authorization,
        currentProjectId,
        resources,
      })
    }, 1000)
  )

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <ConnectedRoutes />
        </DndProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
