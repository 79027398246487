import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'

import { password } from '../../redux/actions/resource'
import { getSignInError } from '../../redux/reducers'

import { Actions, Background, Error, LoginButton, LoginInput } from './styles'

const RequestPasswordReset = ({ resetPassword, error, t }) => (
  <Background>
    {error ? <Error>Error signing in: {error}</Error> : null}
    <Form onSubmit={({ email }) => resetPassword(email)}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h2>{t('forgotPassword')}</h2>
          <LoginInput
            name="email"
            placeholder={t('email')}
            autoComplete="username"
          />
          <Actions>
            <Link to="/login">{t('signIn')}</Link>
            <LoginButton type="submit">{t('resetPassword')}</LoginButton>
          </Actions>
        </form>
      )}
    </Form>
  </Background>
)

const mapStateToProps = (state) => ({
  error: getSignInError(state),
})

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) => dispatch(password.create(null, { email })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RequestPasswordReset))
