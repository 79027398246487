import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import parseJSON from 'date-fns/parseJSON'

import { getShiftById } from '../../redux/reducers'
import { shifts, shiftPublishAttempts } from '../../redux/actions/resource'

import Action, { Actions } from '../../components/Action'
import { Button } from '../../components/forms/buttons'
import Icon from '../../components/Icon'
import Table, { Body, Column, Header, Row } from '../../components/Table'
import Toolbar from '../../components/Toolbar'
import ValveGroupList from './ValveGroupList'
import { FormToggle } from '../../components/forms'

const AttributeRow = ({ label, value }) => {
  return (
    <Row>
      <Header scope="row">{label}</Header>
      <Column>{value}</Column>
    </Row>
  )
}

const renderIntervalDetails = (interval, t) => {
  switch (interval?.intervalType) {
    case 'on_days':
      return (
        <AttributeRow
          label={t('attributes:shift.weekdays')}
          value={interval?.weekdays
            .map((weekday) => t(`weekdays.${weekday}`))
            .join(', ')}
        />
      )
    case 'every_x_days':
    case 'every_x_hours':
      return (
        <AttributeRow
          label={t('attributes:shift.period')}
          value={interval?.period}
        />
      )
  }
}

const Show = ({ history, match, t }) => {
  const shiftId = match.params.id
  const shift = useSelector((state) => getShiftById(state, shiftId))
  const dispatch = useDispatch()

  const [pendingWorkflow, setPendingWorkflow] = useState()

  useEffect(() => {
    dispatch(shifts.read(shiftId))
  }, [dispatch])

  const publishShift = (workflow) => {
    setPendingWorkflow(workflow)
    dispatch(shiftPublishAttempts.create({ shiftId }, { workflow, wait: 0 }))
  }

  const {
    name,
    programId,
    startsAt,
    endsAt,
    interval,
    availableActions,
    sensorBasedIrrigationEnabled,
    state,
    nextOccurrenceStartsAt,
  } = shift || {}

  return (
    <>
      <Toolbar
        title={name}
        onBack={() => history.push(`/programs/${programId}`)}
      >
        <Action to={`/shifts/${shiftId}/edit`}>
          <Icon name="edit" />
        </Action>
      </Toolbar>
      <Table attributes>
        <Body>
          <AttributeRow label={t('attributes:shift.name')} value={name} />
          <AttributeRow
            label={t('attributes:shift.startsAt')}
            value={t('format:dateTimeLong', { date: parseJSON(startsAt) })}
          />
          <AttributeRow
            label={t('attributes:shift.endsAt')}
            value={t('format:dateTimeLong', { date: parseJSON(endsAt) })}
          />
          <AttributeRow
            label={t('attributes:shift.interval')}
            value={t(
              `attributes:shift.repeatOptions.${interval?.intervalType}`
            )}
          />
          {renderIntervalDetails(interval, t)}
          <AttributeRow
            label={t('attributes:shift.nextOccurrence')}
            value={t('format:dateTimeLong', {
              date: parseJSON(nextOccurrenceStartsAt),
            })}
          />
          <AttributeRow
            label={t('attributes:shift:sensorBasedIrrigationEnabled')}
            value={t(
              `common:${sensorBasedIrrigationEnabled ? 'enabled' : 'disabled'}`
            )}
          />
          <AttributeRow
            label={t('attributes:shift.state')}
            value={t(`attributes:shift.states.${state}`)}
          />
          <Row>
            <Column colSpan={2}>
              <Actions>
                {Object.keys(availableActions || {})
                  .filter((key) => availableActions[key])
                  .map((workflow) => (
                    <Button
                      key={workflow}
                      onClick={() => publishShift(workflow)}
                      waiting={pendingWorkflow === workflow}
                    >
                      {t(`actions:shift.${workflow}`)}
                    </Button>
                  ))}
              </Actions>
            </Column>
          </Row>
        </Body>
      </Table>
      <ValveGroupList shift={shift} t={t} />
    </>
  )
}

export default withRouter(withTranslation()(withTheme(Show)))
