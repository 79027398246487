import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { getProjectById } from '../../redux/reducers'
import { Form } from 'react-final-form'

import { projects } from '../../redux/actions/resource'
import { FormField, StyledForm } from '../../components/forms'
import Icon from '../../components/Icon'
import Action from '../../components/Action'
import Toolbar from '../../components/Toolbar'

class EditProject extends Component {
  componentDidMount() {
    const {
      fetchProject,
      match: {
        params: { id },
      },
    } = this.props
    if (id) {
      fetchProject(id)
    }
  }

  render() {
    const { history, project, updateProject, t } = this.props

    return (
      <>
        <Toolbar title={project.name}>
          <Action form="editProject">
            <Icon name="confirm" />
          </Action>
        </Toolbar>
        <Form
          initialValues={project}
          onSubmit={(attributes) =>
            updateProject(project.id, attributes).promise.then(() => {
              history.push(`/projects/${project.id}`)
            })
          }
        >
          {({ handleSubmit }) => (
            <StyledForm divided id="editProject" onSubmit={handleSubmit}>
              <FormField name="name" label={t('attributes:project.name')} />
            </StyledForm>
          )}
        </Form>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  project:
    ownProps.match.params.id == null
      ? {}
      : getProjectById(state, ownProps.match.params.id),
})

const mapDispatchToProps = (dispatch) => ({
  fetchProject: (id) => dispatch(projects.read(id)),
  updateProject: (id, attributes) =>
    dispatch(projects.update(id, undefined, attributes)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(EditProject)))
