import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import { sensorValues as sensorsResource } from '../../redux/actions/resource'
import { getModuleById, getSensorValueById } from '../../redux/reducers'
import camelize from 'camelize'

import ListTitle from '../../components/composed/ListTitle'
import Action from '../../components/Action'
import Icon from '../../components/Icon'
import Table, { Body, Column, Header, Row } from '../../components/Table'
import Toolbar from '../../components/Toolbar'
import moment from 'moment'
import { getStoredToken } from '../../lib/auth'
import { url } from '../../lib/api'

import { Form } from 'react-final-form'

import { addFlashMessage } from '../../redux/actions/flashMessage'

import {
  Dropdown,
  StyledForm,
  FormField,
  DateTime,
  Button,
} from '../../components/forms'

import parseISO from 'date-fns/parseISO'
import HideForRoles from '../../components/auth/HideForRoles'
import ValveOwnersForm from '../valves/owners/Form'
import SensorOwnersForm from './owners/Form'

const exportTypeOptions = [
  { id: 'aprie_xml', name: 'aprie_xml' },
  { id: 'aprie_csv', name: 'aprie_csv' },
]

const AttributeRow = ({ label, value }) => {
  return (
    <Row>
      <Header scope="row">{label}</Header>
      <Column>{value}</Column>
    </Row>
  )
}

const ExportSectionContainer = styled.div`
  martin-top: 2em;
`

const ExportSection = ({ t, sensor, sensorId }) => {
  // default to whole last month
  const startsAt = moment().subtract(1, 'month').startOf('month').format()
  const endsAt = moment().subtract(1, 'month').endOf('month').format()
  const dispatch = useDispatch()

  return (
    <ExportSectionContainer>
      <ListTitle>{t('common:export')}</ListTitle>
      <Form
        initialValues={{
          startsAt: startsAt,
          endsAt: endsAt,
          exportType: exportTypeOptions[0].id,
        }}
        onSubmit={(attributes) => {
          console.log('submit', attributes)

          if (
            !attributes.startsAt ||
            !attributes.endsAt ||
            !sensorId ||
            !attributes.exportType
          ) {
            console.log('missing params')
            return
          }

          const queryParams = new URLSearchParams()

          queryParams.append('export_type', attributes.exportType)
          queryParams.append('start_date', attributes.startsAt)
          queryParams.append('end_date', attributes.endsAt)

          const endpoint = `${url}/sensor_values/${sensorId}/export?${queryParams.toString()}`

          const token = getStoredToken()

          window
            .fetch(endpoint, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                return response.json()
              } else {
                console.log('Error', response)
                dispatch(
                  addFlashMessage(
                    'Fehler',
                    'Export konnte nicht erstellt werden'
                  )
                )
              }
            })
            .then((data) => {
              if (data) {
                const { downloadUrl } = camelize(data)
                window.open(downloadUrl)
              }
            })
            .catch((error) => console.error('Error:', error))
        }}
      >
        {({ handleSubmit, values }) => (
          <StyledForm id="exportSensorData" divided onSubmit={handleSubmit}>
            <FormField
              name="startsAt"
              label={t('attributes:shift.startsAt')}
              component={DateTime}
              required
            />
            <FormField
              name="endsAt"
              label={t('attributes:shift.endsAt')}
              component={DateTime}
              minDate={parseISO(values.startsAt)}
              required
            />
            <FormField
              name="exportType"
              label={t('attributes:sensor.exportType')}
              options={[...exportTypeOptions]}
              component={Dropdown}
            />

            <Button smaller type="submit">
              {t('common:download')}
            </Button>
          </StyledForm>
        )}
      </Form>
    </ExportSectionContainer>
  )
}

const Show = ({ history, match, t }) => {
  const sensorId = match.params.id
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(sensorsResource.read(sensorId))
  }, [dispatch])

  const sensor =
    useSelector((state) => getSensorValueById(state, sensorId)) || {}

  const fieldModule =
    useSelector((state) => getModuleById(state, sensor.wateringModuleId)) || {}

  return (
    <>
      <Toolbar title={sensor.name} onBack={() => history.goBack()}>
        <Action to={`/sensors/${sensorId}/edit`}>
          <Icon name="edit" />
        </Action>
      </Toolbar>
      <Table attributes>
        <Body>
          <AttributeRow
            label={t('attributes:sensor.name')}
            value={sensor.name}
          />
          <AttributeRow
            label={t('attributes:sensor.sortOrder')}
            value={sensor.sortOrder}
          />
          <AttributeRow
            label={t('attributes:sensor.showOnMap')}
            value={t(`common:${sensor.showOnMap ? 'yes' : 'no'}`)}
          />
          <AttributeRow
            label={t('attributes:sensor.showOnDashboard')}
            value={t(`common:${sensor.showOnDashboard ? 'yes' : 'no'}`)}
          />
          <AttributeRow
            label={t('attributes:sensor.availableForSensorBasedIrrigation')}
            value={t(
              `common:${
                sensor.availableForSensorBasedIrrigation ? 'yes' : 'no'
              }`
            )}
          />
          <AttributeRow
            label={t('attributes:sensor.availableForSensorThresholdIrrigation')}
            value={t(
              `common:${
                sensor.availableForSensorThresholdIrrigation ? 'yes' : 'no'
              }`
            )}
          />
          {fieldModule && (
            <AttributeRow
              label={t('models:fieldModule')}
              value={
                <span
                  onClick={() => history.push(`/modules/${fieldModule.id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  {fieldModule.name}
                </span>
              }
            />
          )}
        </Body>
      </Table>
      <HideForRoles roles={['farmer']}>
        <ListTitle>
          <span>{t('attributes:sensor:owners:title')}</span>
        </ListTitle>
        <SensorOwnersForm sensor={sensor} />
      </HideForRoles>

      <ExportSection t={t} sensor={sensor} sensorId={sensorId} />
    </>
  )
}

export default withRouter(withTranslation()(withTheme(Show)))
