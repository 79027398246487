import debounce from 'debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import has from 'styled-is'

import {
  modules as modulesResource,
  search as searchResource,
} from '../../redux/actions/resource'
import {
  getAllModules,
  getCurrentProjectId,
  getMapSearch,
} from '../../redux/reducers'

import Action from '../../components/Action'
import {
  ClosableSearch,
  SearchWrapper,
  CloseIcon,
} from '../../components/forms'
import { Spinner } from '../../components/forms/buttons'
import { Options } from '../../components/forms/Dropdown'
import Icon from '../../components/Icon'
import Toolbar from '../../components/Toolbar'

import ModuleMarker from './ModuleMarker'
import ModuleOverlay from './ModuleOverlay'

const StyledMap = styled(({ className, ...props }) => (
  <GoogleMap
    options={{
      disableDefaultUI: true,
      mapTypeId: 'hybrid',
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }}
    mapContainerClassName={className}
    {...props}
  />
))`
  margin: 68px -2rem 0;
  width: calc(100% + 4rem);
  // TODO: avoid magic number
  height: calc(100% - 40px);
  transition: height 0.1s ease;
  ${has('overlay')`
    height: calc(25%);
  `};
`

const hasLatLng = ({ lng, lat }) => lat && lng
const libraries = ['geometry', 'drawing', 'places']

const useQuery = () => new URLSearchParams(useLocation().search)

const Map = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY,
    libraries,
  })
  const selectedModuleId = useQuery().get('moduleId')
  const [map, setMap] = useState(null)
  const [currentZoom, setCurrentZoom] = useState(null)
  const [isSearching, setSearching] = useState(false)

  const searchFieldRef = useRef(null)

  const searchResults = useSelector(getMapSearch)

  const projectId = useSelector(getCurrentProjectId)
  const modules = useSelector((state) => getAllModules(state, hasLatLng))

  useEffect(() => {
    dispatch(modulesResource.list({ projectId }))
  }, [dispatch])

  const onLoad = useCallback((map) => {
    setMap(map)
  })
  const onUnmount = useCallback(() => {
    setMap(null)
  })

  useEffect(() => {
    if (isSearching) {
      dispatch(searchResource.read('map', { projectId }))
      searchFieldRef?.current?.focus()
    }
  }, [isSearching, searchFieldRef])

  const onSearch = (search) => {
    dispatch(searchResource.read('map', { projectId, search }))
  }
  const [searchParams, updateSearchParams] = useState('')
  const debouncedSearch = useRef(debounce(onSearch, 1000)).current
  const throttleSearch = (e) => {
    const inputValue = e.target.value
    updateSearchParams(inputValue)
    const params = new window.URLSearchParams()
    if (inputValue) {
      params.append('search_keyword', inputValue)
    }
    debouncedSearch(params)
  }

  const moduleIdSum = modules.reduce((sum, { id }) => sum + id, 0)
  useEffect(() => {
    if (map != null) {
      const bounds = modules.reduce(
        (bounds, { lat, lng }) => bounds.extend({ lat, lng }),
        new window.google.maps.LatLngBounds()
      )
      map.fitBounds(bounds)
      map.setCenter(bounds.getCenter())
    }
    // module array changes too often, projectId and sum of moduleIds should be
    // a safe indicator for changed project and loaded modules.
  }, [map, projectId, moduleIdSum])

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    return (
      <StyledMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        zoom={18}
        onZoomChanged={() => setCurrentZoom(map && map.zoom)}
        overlay={selectedModuleId != null}
      >
        {(modules || []).map((module, index) => {
          return (
            <ModuleMarker
              key={index}
              onClick={() => history.push(`/map?moduleId=${module.id}`)}
              selected={module.id === +selectedModuleId}
              module={module}
              currentZoom={currentZoom}
            />
          )
        })}
      </StyledMap>
    )
  }

  return (
    <>
      <Toolbar
        onBack={() => history.push('/dashboard')}
        search={
          isSearching ? (
            <SearchWrapper>
              <CloseIcon
                onClick={() => {
                  updateSearchParams('')
                  setSearching(false)
                }}
              />
              <ClosableSearch
                ref={searchFieldRef}
                value={searchParams}
                onChange={throttleSearch}
              />
              <Options open>
                {(searchResults || []).map(
                  ({ id, name, type, wateringModuleId }, index) => (
                    <li key={index}>
                      <a
                        onClick={() => {
                          history.push(
                            `/map?moduleId=${
                              ['Valve'].includes(type) ? wateringModuleId : id
                            }`
                          )
                          updateSearchParams('')
                          setSearching(false)
                        }}
                      >
                        {name}
                      </a>
                    </li>
                  )
                )}
              </Options>
            </SearchWrapper>
          ) : null
        }
      >
        <Action onClick={() => setSearching(true)}>
          <Icon name="search" />
        </Action>
      </Toolbar>
      {isLoaded ? renderMap() : <Spinner />}
      {selectedModuleId != null && (
        <ModuleOverlay
          moduleId={+selectedModuleId}
          onClose={() => history.push('/map')}
        />
      )}
    </>
  )
}

export default Map
