import React from 'react'
import { useSelector } from 'react-redux'
import { getSensorValueById } from '../../../../redux/reducers'
import {
  MULTI_THRESHOLD_IRRIGATION_TYPE,
  SENSOR_THRESHOLD_IRRIGATION_TYPE,
  SINGLE_THRESHOLD_IRRIGATION_TYPE,
} from '../constants'
import { withTranslation } from 'react-i18next'
import { Column, Row } from '../../../../components/Table'

/*
 * Shows current threshold value and its type. If
 */
const CurrentThreshValue = ({ valve, t }) => {
  if (
    valve === null ||
    valve === undefined ||
    !valve.sensorBasedIrrigationEnabled
  ) {
    return <></>
  }
  const { sensorBasedIrrigation } = valve || {}
  const { threshSensorValueId, type, thresh0, thresh100 } =
    sensorBasedIrrigation || {}
  const threshSensor = useSelector((state) =>
    getSensorValueById(state, threshSensorValueId)
  )
  const sensorThreshValue = threshSensor?.currentValue?.value
  const sensorThreshTimestamp = threshSensor?.currentValue?.timestamp

  const sensorUnit = 'h_pa'

  return (
    <>
      {type === SENSOR_THRESHOLD_IRRIGATION_TYPE && (
        <>
          <span style={{ fontWeight: 'bold', paddingRight: '1em' }}>
            {t(`attributes:valve:sensorBasedIrrigation:thresholdAuto`)}
          </span>
          {sensorThreshValue !== null ? (
            <>
              <span style={{ marginRight: '1em' }}>{sensorThreshValue}hPa</span>
              <span style={{ color: 'lightgray' }}>
                (
                {t(`attributes:valve:sensorBasedIrrigation:timestamp`, {
                  timestamp: sensorThreshTimestamp,
                })}
                )
              </span>
            </>
          ) : (
            <span>-</span>
          )}
        </>
      )}
      {type === SINGLE_THRESHOLD_IRRIGATION_TYPE && (
        <>
          <span style={{ fontWeight: 'bold', paddingRight: '1em' }}>
            {t(`attributes:valve:sensorBasedIrrigation:thresholdManual`)}
          </span>
          {thresh0 !== null ? <span>{thresh0}hPa</span> : <span>-</span>}
        </>
      )}
      {type === MULTI_THRESHOLD_IRRIGATION_TYPE && (
        <>
          <span style={{ fontWeight: 'bold', paddingRight: '1em' }}>
            {t(`attributes:valve:sensorBasedIrrigation:thresholdsManual`)}
          </span>
          <Row>
            <Column>
              {t('attributes:valve:sensorBasedIrrigation.thresh0')}: {thresh0}
              {sensorUnit &&
                t(`attributes:sensorValue:unitOptions:${sensorUnit}`)}
            </Column>
          </Row>
          <Row>
            <Column>
              {t('attributes:valve:sensorBasedIrrigation.thresh100')}:{' '}
              {thresh100}{' '}
              {t(`attributes:sensorValue:unitOptions:${sensorUnit}`)}
            </Column>
          </Row>
        </>
      )}
    </>
  )
}

export default withTranslation()(CurrentThreshValue)
