import React from 'react'

import { Route, Switch } from 'react-router-dom'

import List from './List'
import Edit from './Edit'
import Show from './Show'
import ReplaceModule from './ReplaceModule'

export default () => (
  <Switch>
    <Route path="/modules" exact component={List} />
    <Route path="/modules/:id/replace" exact component={ReplaceModule} />
    <Route path="/modules/:id/edit" exact component={Edit} />
    <Route path="/modules/:id" exact component={Show} />
  </Switch>
)
