import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Show from './Show'
import Edit from './Edit'
import List from './List'

export default () => (
  <Switch>
    <Route path="/projects/new" exact component={Edit} />
    <Route path="/projects/:id" exact component={Show} />
    <Route path="/projects/:id/edit" exact component={Edit} />
    <Route path="/projects/" exact component={List} />
  </Switch>
)
