import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Show from './Show'
import Edit from './Edit'
// import List from './List'

export default () => (
  <Switch>
    {/* <Route path="/sensors/new" exact component={Edit} /> */}
    <Route path="/sensors/:id" exact component={Show} />
    <Route path="/sensors/:id/edit" exact component={Edit} />
    {/* <Route path="/sensors/" exact component={List} /> */}
  </Switch>
)
