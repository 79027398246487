import React from 'react'

import styled from 'styled-components'

import { fontSmoothing } from '../../styles'

import { Button, Input } from '../../components/forms'

export const Error = styled.p`
  font-weight: bolder;
  color: lightsalmon;
  margin-bottom: 1rem;
`

export const Background = styled.div`
  background: ${(props) => props.theme.colors.brandGradient};
  ${fontSmoothing};

  h1 {
    color: ${(props) => props.theme.colors.background};
    text-align: center;
    font-weight: normal;
    margin: 6rem 0 2rem;
  }

  & a,
  & button {
    color: ${(props) => props.theme.colors.background};
    text-decoration: none;
    background: transparent;
    font-weight: normal;
  }

  margin: -2rem;
  padding: 2rem;
  height: calc(100vh - 70px);
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
`

export const LoginInput = styled(Input)`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  border-bottom: none;

  height: auto;
  margin: 24px 0;
  padding: 12px 24px;
  border-radius: 24px;

  ::placeholder {
    color: ${(props) => props.theme.colors.inactive};
  }
`

export const LoginButton = styled(Button).attrs({ autoSize: false })`
  border-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.background};
`
